import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const perfectMatchSlice = createSlice({
    name: 'ui/perfect-match-list',
    initialState: {
        ...listUIInitialState,
        autoScheduleMeetingModalInfo: {
            visible: false,
            companies: [],
            contacts: [],
            matchList: null,
            perfectListId: null
        }
    },
    reducers: {
        ...listUIReducers,
        setAutoScheduleMeetingModal(state, action) {
            Object.keys(action.payload).map(key => {
                state.autoScheduleMeetingModalInfo[key] = action.payload[key]
            })
        }
    }
})

export const perfectMatchActions = perfectMatchSlice.actions
export default perfectMatchSlice.reducer
