import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteBulkUnavailabilitiesPayload } from '@app-store/slices/meetings/deleteBulkUnavailabilities'

interface TaskAction extends Action {
    payload: IDeleteBulkUnavailabilitiesPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetings.deleteBulkUnavailabilities

function* deleteBulkUnavailabilitiesSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/meeting/unavailable/bulk-remove`, {
            ids: payload.ids
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result)
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteBulkUnavailabilitiesSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteBulkUnavailabilitiesSagaTask)
}

export default deleteBulkUnavailabilitiesSaga
