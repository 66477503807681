import { IColumn } from '@features/General'
import CloseLineIcon from 'remixicon-react/CloseLineIcon'
import CheckLineIcon from 'remixicon-react/CheckLineIcon'
import SecurePaymentLineIcon from 'remixicon-react/SecurePaymentLineIcon'
import ExternalLinkFillIcon from 'remixicon-react/ExternalLinkFillIcon'
import { SubscriptionOrderStatusColors, SubscriptionOrderStatus } from '../index'
import store from '@store/store'
import { rootActions } from '@app-store/slices'
import { history } from '@components/App'
import routes from '@utils/constants/routes'

export const listColumns = (i18n, userId, appDirection): IColumn[] => [
    {
        title: i18n?.General?.Company,
        key: 'company',
        type: 'nested',
        fields: ['name'],
        allowHide: true
    },
    {
        title: i18n?.TradeHub?.Plan_Name || 'Plan Name',
        dataIndex: 'subscription',
        key: 'subscriptionName',
        type: 'nested',
        fields: ['name'],
        allowHide: true
    },
    {
        title: i18n?.TradeHub?.Price || 'Price',
        key: 'price',
        allowHide: true
    },
    {
        title: i18n?.General?.User,
        key: 'user',
        type: 'nested',
        fields: ['firstname', 'lastname'],
        allowHide: true
    },
    {
        title: i18n?.TradeHub?.Max_Subscribers || 'Max Subscribers',
        dataIndex: 'subscription',
        key: 'subscriptionMaxSubscribers',
        type: 'nested',
        fields: ['maxSubscribers'],
        allowHide: true
    },
    {
        title: i18n?.TradeHub?.Points || 'Points',
        dataIndex: 'subscription',
        key: 'subscriptionPoints',
        type: 'nested',
        fields: ['points'],
        allowHide: true
    },
    {
        title: i18n?.General?.Status,
        dataIndex: 'status',
        key: 'orderStatus',
        type: 'badge',
        bg: value => SubscriptionOrderStatusColors[value],
        align: appDirection === 'rtl' ? 'right' : 'left'
    },
    {
        title: i18n?.TradeHub?.Approved_date || 'Approved date',
        key: 'approvedAt',
        type: 'dateTime',
        format: 'DD.MM.YYYY',
        allowHide: true,
        sorter: true
    },
    {
        key: 'actions',
        type: 'actions',
        actions: record => [
            {
                title: i18n?.General?.GeneratePaymentLink || 'Generate Payment Link',
                icon: ExternalLinkFillIcon,
                key: 'payment-link',
                hide: ![SubscriptionOrderStatus.PENDING].includes(record.status),
                onClick: record =>
                    store.dispatch(
                        rootActions.ui.orderHistory.setGenerateLinkModalInfo({
                            isOpen: true,
                            orderId: record._id,
                            userId: record.user._id || '',
                            subscriptionId: record.subscription._id || '',
                            userOptionId: record.userOption || '',
                            setterOptionId: record.userOption || ''
                        })
                    )
            },
            {
                title: i18n?.General?.Cancel,
                icon: CloseLineIcon,
                key: 'cancel',
                abilityAction: 'EditOrder',
                abilityService: 'Subscription',
                hide:
                    record.user?._id !== userId ||
                    ![SubscriptionOrderStatus.PENDING, SubscriptionOrderStatus.WAITING].includes(record.status),
                onClick: record =>
                    store.dispatch(
                        rootActions.orders.cancel.onRequest({
                            id: record._id
                        })
                    )
            },
            {
                title: i18n?.TradeHub?.Pay || 'Pay',
                icon: SecurePaymentLineIcon,
                key: 'pay',
                hide: record.user?._id !== userId || record.status !== SubscriptionOrderStatus.PENDING,
                onClick: record =>
                    history.push(routes.settings.subscriptions.orders.payment._.replace(':id', record._id))
            },
            {
                title: i18n?.General?.Approve || 'Approve',
                icon: CheckLineIcon,
                key: 'approve',
                abilityAction: 'ApproveOrder',
                abilityService: 'Subscription',
                hide: record.status !== SubscriptionOrderStatus.WAITING,
                onClick: record => store.dispatch(rootActions.orders.approve.onRequest({ id: record._id }))
            },
            {
                title: i18n?.General?.Decline || 'Decline',
                icon: CloseLineIcon,
                key: 'reject',
                abilityAction: 'RejectOrder',
                abilityService: 'Subscription',
                hide: record.status !== SubscriptionOrderStatus.WAITING,
                onClick: record => store.dispatch(rootActions.orders.reject.onRequest({ id: record._id }))
            }
        ]
    }
]
