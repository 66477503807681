import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'

export interface IListState {
    status: string
    data: any
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const oneContactSlice = createSlice({
    name: 'hub/contacts/one-contact',
    initialState,
    reducers: {
        onRequest(state, action) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload?.data?._id ? action.payload.data : action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        },
        changeAvatar(state, action) {
            state.data.avatar = action.payload.avatar
        },
        onChangeData(state, action) {
            Object.keys(action.payload.data).forEach(key => {
                state.data[key] = action.payload.data[key]
            })
        },
        onChangeDetailsOneContact(state, action) {
            if (state.data) {
                const contactDetailIndex = state.data?.contactDetails?.findIndex(
                    item => item._id === action.payload._id
                )
                if (contactDetailIndex >= 0) {
                    state.data.contactDetails[contactDetailIndex] = action.payload
                } else if (action.payload.isPrimary) state.data.contactDetails = [{ ...action.payload }]
            }
        }
    }
})

export const oneContactActions = oneContactSlice.actions
export default oneContactSlice.reducer
