import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { IListBySearchPayload } from '@app-store/slices/categories/listBySearch'

interface TaskAction extends Action {
    payload: IListBySearchPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.categories.listBySearch

function* listBySearchSagaTask({ payload }: TaskAction) {
    const params = yield getParams(payload)
    try {
        const response = yield axios.get(`/companies/categories/search?searchString=${payload.search}`, {
            params
        })
        if (response.success) {
            yield put(onSuccess({ ...response.result }))
            yield payload.sagaCB?.onSuccess(response.result.data)
        }
    } catch (error) {
        if (typeof payload.sagaCB?.onError === 'function') {
            payload.sagaCB?.onError(error)
        }
        yield put(onFailure(error))
    }
}

function* listBySearchSaga(): any {
    yield takeLatest<TaskAction>(onRequest, listBySearchSagaTask)
}

export default listBySearchSaga
