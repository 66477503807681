import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDisableUserPayload } from '@app-store/slices/users/disable'

interface TaskAction extends Action {
    payload: IDisableUserPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.users.disable

function* disableUserSagaTask({ payload }) {
    try {
        const response = yield axios.delete(`/users/disable/${payload.data.id}`)
        if (response.success) {
            yield put(onSuccess(''))
            if (payload?.sagaCB) {
                yield payload?.sagaCB?.onSuccess(response.result)
            }
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* disableUserSaga(): any {
    yield takeLatest<TaskAction>(onRequest, disableUserSagaTask)
}

export default disableUserSaga
