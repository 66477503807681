import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ICreateEmailCredential extends ISagaCb {
    data: {
        title: string
        type: 'Gmail' | 'SES' | 'SMTP' | 'Outlook'
        fromEmail?: string
        host?: string
        port?: string
        username?: string
        password?: string
        providerToken?: string
    }
}

export interface IListState {
    status: string
    data: any[]
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const create = createSlice({
    name: 'email-credential/create',
    initialState,
    reducers: {
        onRequest(state, actions: PayloadAction<ICreateEmailCredential>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createActions = create.actions
export default create.reducer
