import { getSubDomain } from '@features/Auth'
import routes from '@utils/constants/routes'
import { rootActions } from '@store/slices'
import { COOKIE_KEYS, getCookie, setCookie } from '../index'
import store from '@app-store/store'

export const getBundles = path => {
    const bundles = ['General']
    switch (true) {
        case path.includes(routes.pipeline.trade_hub._):
            bundles.push('TradeHub')
            break
        case path.includes(routes.organizer._):
        case path.includes(routes.analytics._):
            bundles.push('Dashboard')
            bundles.push('Event')
            bundles.push('TradeHub')
            break
        case path.includes(routes.pipeline._):
            bundles.push('TradeHub')
            bundles.push('Dashboard')
            break
        case path.includes(routes.meeting_request._):
        case path.includes(routes.meetings._):
            bundles.push('Meeting')
            bundles.push('EventAgenda')
            bundles.push('TradeHub')
            bundles.push('Dashboard')
            break
        case path.includes(routes.matching._):
            bundles.push('Matching')
            bundles.push('TradeHub')
            bundles.push('Meeting')
            bundles.push('Dashboard')
            break
        case path.includes(routes.settings._):
            bundles.push('Event')
            bundles.push('Settings')
            bundles.push('Meeting')
            bundles.push('EventAgenda')
            bundles.push('TradeHub')
            bundles.push('Dashboard')
            bundles.push('Integration')
            bundles.push('Matching')
            break
        case path.includes(routes.directory._):
            bundles.push('TradeHub')
            bundles.push('Settings')
            bundles.push('Meeting')
            bundles.push('Dashboard')
            bundles.push('Matching')
            break
        case path.includes(routes.subscription._):
            bundles.push('TradeHub')
            bundles.push('Dashboard')
            break
        case path.includes(routes.alarms._):
            bundles.push('Meeting')
            bundles.push('TradeHub')
            bundles.push('Dashboard')
            break
        case path.includes(routes.crm._):
            bundles.push('Settings')
            bundles.push('Dashboard')
            bundles.push('TradeHub')
            bundles.push('Integration')
            break
        case path.includes(routes.database_import._):
            bundles.push('Event')
            bundles.push('TradeHub')
            bundles.push('Settings')
            bundles.push('Dashboard')
            break
        case path.includes(routes.exhibitor._):
            bundles.push('TradeHub')
            bundles.push('Matching')
            bundles.push('Dashboard')
            bundles.push('Meeting')
            break
    }
    return bundles
}
const isValidRoute = path => {
    const pathSections = path.split('/')
    if (
        pathSections.length !== 2 ||
        (pathSections.length === 2 &&
            [
                'login',
                'sign-up',
                'support',
                'confirmation',
                'new-password',
                'forgot-password',
                'activation',
                'itp',
                'public',
                'crm',
                'otp-login',
                'invalid-sub-domain'
            ].includes(pathSections[1]))
    )
        return true
    return false
}
const isValidPath = path =>
    [
        routes.meetings._,
        routes.meetings.calendar._,
        routes.meetings.wish_campaign.meeting_request.calendar._,
        routes.meeting_request.request.participants._,
        routes.meetings.calendar.clone._,
        routes.meetings.calendar.create._,
        routes.meetings.calendar.edit._
    ].includes(path)
export const getExtraKeys = path => {
    const subdomain = getSubDomain()
    const extraKeys = [
        'My_Company',
        'My_Profile',
        'Open_Guide_Tour',
        'Open_Guide_Video',
        'Match',
        'Application_Demo',
        'Save_And_Leave',
        'Skip',
        'Meeting Request',
        'Delete_Description',
        'MatchList_Name',
        'Give_MatchList_Group',
        'Match_List_Saved',
        'Match_List_Section',
        'Go_Match_List',
        'Welcome_To',
        'Dashboard',
        'Directory',
        'Meetings',
        'Matching',
        'Tradhub_Pipeline',
        'Reports',
        'Settings',
        'Generate_URL',
        'Generated_link_copied_clipboard',
        'Details'
    ]
    const itpExtraKeys = [
        'Department',
        'Targeted_Location',
        'Job_Titles',
        'Company_Size',
        'Example_Client_Websites',
        'Example_Competitors_Websites',
        'Keywords',
        'Investment_Stage',
        'Tag_Name',
        'Add_Tag',
        'Companytype'
    ]
    switch (true) {
        case path.includes(routes.pipeline.trade_hub._):
            break
        case path.includes(routes.directory.company_profile._):
            extraKeys.push('Search_Box_Letters', ...itpExtraKeys)
        case path.includes('itp/profile'):
            extraKeys.push('Selected_Companies', 'please_sign_in_to_learn', ...itpExtraKeys)
            break
        case path.includes('unsubscribe'):
            extraKeys.push('Reason_Optional')
            break
        case ['meeting', 'dev'].includes(subdomain) || isValidPath(path):
            if (!isValidRoute(path) || isValidPath(path))
                extraKeys.push(
                    'Jon_Position',
                    'Reason',
                    'Meeting_Type',
                    'Time_Zone',
                    'How_long_duration_should_be',
                    'What_time_work_best_for_you'
                )
            break
    }
    return extraKeys
}

export const changeAppLanguage = language => {
    const eventId = getCookie(COOKIE_KEYS.ALA_EVENT)
    setCookie(COOKIE_KEYS.LANG, language)
    if (eventId) {
        store.dispatch(
            rootActions.localization.updateLanguage.onRequest({
                data: { language },
                sagaCB: {
                    onSuccess: res => {
                        location.reload()
                    }
                }
            })
        )
    } else {
        location.reload()
    }
}

export const getLanguageLabels = labuage => {
    switch (labuage) {
        case 'en':
            return 'English'
            break
        case 'ar':
            return 'Arabic'
            break
        case 'fr':
            return 'French'
            break
    }
}
