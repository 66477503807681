import { createSlice } from '@reduxjs/toolkit'
import { listUIReducers } from '../reducers'
import { listUIInitialState } from '@utils/constants/requestConstants'

interface IEventEmailCampaignStep {
    subject: string
    sendAt: string
    sendDate: string
    content: string
    hasChanged: boolean
}

export interface IEventEmailCampaignUIState {
    downloadStatisticsModal: {
        isOpen: boolean
        stepId: string
        campaignId: string
    }
    newCampaignModalVisible: boolean
    newStepsModalVisible: boolean
    copyModal: boolean
    isWishCampaign: boolean
    showContactsWishCampaign: boolean
}

export const initialEmailCampaignStepData: IEventEmailCampaignStep = {
    subject: '',
    sendAt: '',
    sendDate: '',
    content: '',
    hasChanged: false
}

const initialState: IEventEmailCampaignUIState = {
    downloadStatisticsModal: {
        isOpen: false,
        stepId: '',
        campaignId: ''
    },
    newCampaignModalVisible: false,
    newStepsModalVisible: false,
    copyModal: false,
    isWishCampaign: false,
    showContactsWishCampaign: false
}
const eventEmailCampaignSlice = createSlice({
    name: 'ui/event-email-campaign',
    initialState: {
        ...listUIInitialState,
        ...initialState,
        filters: { ...listUIInitialState.filters, status: ['Pending', 'Ongoig'] },
        pureFilters: { ...listUIInitialState.pureFilters, status: ['Pending', 'Ongoig'] }
    },
    reducers: {
        ...listUIReducers,
        onChangeModalsData(state, action) {
            Object.keys(action.payload).forEach(key => {
                state[key] = action.payload[key]
            })
        },
        reset(state) {
            state = {
                ...listUIInitialState,
                ...initialState,
                filters: { ...listUIInitialState.filters },
                pureFilters: { ...listUIInitialState.pureFilters }
            }
        },
        changeDownloadStatisticsModalData(state, action) {
            Object.keys(action.payload).forEach(key => {
                state.downloadStatisticsModal[key] = action.payload[key]
            })
        }
    }
})

export const emailCampaignActions = eventEmailCampaignSlice.actions
export default eventEmailCampaignSlice.reducer
