import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IRevealPayload extends ISagaCb {
    id: string
}

export interface IListState {
    status: string
    data: any
    errors: any
}

const initialState: IListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const revealSlice = createSlice({
    name: 'hub/contacts/reveal',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IRevealPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const revealActions = revealSlice.actions
export default revealSlice.reducer
