import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { IGetUnavailableSlotsPayload } from '@app-store/slices/meetings/getUnavailableSlots'

interface TaskAction extends Action {
    payload: IGetUnavailableSlotsPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetings.getUnavailableSlots

function* getUnavailableSlotsTask({ payload }) {
    const params = yield getParams(payload)
    try {
        const response = yield axios.get(`/meeting/unavailable`, {
            params,
            headers: {
                'event-id': payload.event
            }
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload.sagaCB) {
                yield payload.sagaCB.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        if (typeof payload?.sagaCB?.onError === 'function') {
            payload.sagaCB.onError()
        }
        yield put(onFailure(''))
    }
}

function* getUnavailableSlots(): any {
    yield takeLatest<TaskAction>(onRequest, getUnavailableSlotsTask)
}

export default getUnavailableSlots
