import { STATUS } from '@features/General'
import { MeetingType } from '@features/Meeting/MeetingAgenda'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface ICreateBulkMeetingPayload extends ISagaCb {
    data: {
        meetingDetails?: {
            hostContactIds: string[]
            guestContactIds: string[]
            hostCompanyId: string
            guestCompanyId: string
            perfectListId: string
        }[]
        meetingType: keyof typeof MeetingType
        meetingDuration: number
        hallId?: string
        place?: string
        fromTime?: string
        toTime?: string
        meetingDates?: string
    }
}

export interface ICreateBulkMeetingState {
    status: string
    data: any
    errors: any
}

const initialState: ICreateBulkMeetingState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const createBulkMeeting = createSlice({
    name: 'meeting/create-bulk',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ICreateBulkMeetingPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const createBulkMeetingActions = createBulkMeeting.actions
export default createBulkMeeting.reducer
