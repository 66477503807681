import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IReShareSharedListPayload extends ISagaCb {
    data: {
        emailSubject: string
        matchList: string
        sharedWithCompanies: any
        emailContent: string
        showScores: boolean
        showMaskContacts: boolean
    }
}

export interface IReShareSharedListState {
    status: string
    data: any
    errors: any
}

const initialState: IReShareSharedListState = {
    status: STATUS.IDLE,
    data: null,
    errors: null
}

const reShareSharedList = createSlice({
    name: 'sharedList/re-share',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IReShareSharedListPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const reShareSharedListActions = reShareSharedList.actions
export default reShareSharedList.reducer
