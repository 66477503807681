import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IRevealPayload } from '@app-store/slices/contacts/reveal'

interface TaskAction extends Action {
    payload: IRevealPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.contacts.reveal
function* revealSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/companies/contacts/reveal/${payload.id}`)
        if (response.success) {
            yield put(onSuccess(response.result))
            yield payload?.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* revealSaga(): any {
    yield takeLatest<TaskAction>(onRequest, revealSagaTask)
}

export default revealSaga
