import listSaga from './listSaga'
import listSearchSaga from './listSearchSaga'
import profileSaga from './profileSaga'
import createSaga from './createSaga'
import editSaga from './editSaga'
import editStatusSaga from './editStatusSaga'
import deleteSaga from './deleteSaga'
import uploadImageSaga from './uploadImageSaga'
import reinviteSaga from './reinviteSaga'
import getPreSignedUploadURLSaga from './getPreSignedUploadURLSaga'
import changeStatusSaga from './changeStatusSaga'
import existingUserCreateSaga from './existingUserCreateSaga'
import addUserCreateSaga from './addUserCreateSaga'
import resetPasswordSaga from './resetPasswordSaga'
import activationSaga from './activationSaga'
import sendInvitationSaga from './sendInvitationSaga'
import oneByEmailSaga from './oneByEmail'
import findUserSaga from './findUser'
import confirmUserSaga from './confirmUserSaga'
import revokeEventAccessSaga from './revokeEventAccess'
import disableSaga from './disable'

const usersSaga = [
    listSaga(),
    listSearchSaga(),
    profileSaga(),
    createSaga(),
    editSaga(),
    editStatusSaga(),
    deleteSaga(),
    uploadImageSaga(),
    reinviteSaga(),
    getPreSignedUploadURLSaga(),
    changeStatusSaga(),
    existingUserCreateSaga(),
    addUserCreateSaga(),
    resetPasswordSaga(),
    activationSaga(),
    sendInvitationSaga(),
    oneByEmailSaga(),
    findUserSaga(),
    confirmUserSaga(),
    revokeEventAccessSaga(),
    disableSaga()
]

export default usersSaga
