import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action {
    payload: any
}

const { onRequest, onSuccess, onFailure } = rootActions.survey.list

function* listSagaTask({ payload }: TaskAction) {
    const params = {
        ...payload.pagination,
        query: payload.query
    }
    try {
        const response = yield axios.get('/meeting/survey', {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB) payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listSaga(): any {
    yield takeLatest<TaskAction>(onRequest, listSagaTask)
}

export default listSaga
