import createSurveyFeedbackSaga from './createSurveyFeedbackSaga'
import createSurveyFeedbackByTokenSaga from './createSurveyFeedbackByTokenSaga'
import oneSurveySaga from './oneSurveySaga'
import oneSurveyByTokenSaga from './oneSurveyByTokenSaga'
import listSaga from './listSaga'
import feedbackListSaga from './feedbackListSaga'
import staticEnumsSaga from './staticEnumsSaga'
import createSurveySaga from './createSurveySaga'
import deleteSurveySaga from './deleteSurveySaga'
import editSurveySaga from './editSurveySaga'
import generateLinkSaga from './generateLinkSaga'

const surveySagas = [
    createSurveyFeedbackSaga(),
    createSurveyFeedbackByTokenSaga(),
    oneSurveySaga(),
    oneSurveyByTokenSaga(),
    listSaga(),
    feedbackListSaga(),
    staticEnumsSaga(),
    createSurveySaga(),
    deleteSurveySaga(),
    editSurveySaga(),
    generateLinkSaga()
]

export default surveySagas
