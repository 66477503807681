import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
export interface IDisableUserPayload extends ISagaCb {
    data: {
        id: string
    }
}

interface IDisableState {
    status: string
    errors: any
}

const initialState: IDisableState = {
    status: STATUS.IDLE,
    errors: null
}

const disableSlice = createSlice({
    name: 'hub/user/disable',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IDisableUserPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const disableActions = disableSlice.actions
export default disableSlice.reducer
