import { defaultDarkColor, defaultLightColor, defaultPrimaryColor } from 'config'

export const pickForeColor = (bgColor = '#FFFFFF', lightColor = defaultLightColor, darkColor = defaultDarkColor) => {
    const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
    const r = parseInt(color.substring(0, 2), 16) // hexToR
    const g = parseInt(color.substring(2, 4), 16) // hexToG
    const b = parseInt(color.substring(4, 6), 16) // hexToB
    const uicolors = [r / 255, g / 255, b / 255]
    const c = uicolors.map(col => {
        if (col <= 0.03928) {
            return col / 12.92
        }
        return Math.pow((col + 0.055) / 1.055, 2.4)
    })
    const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
    return L > 0.315 && bgColor !== '#ff681a' ? darkColor : lightColor
}

export const eventColorHelper = event => {
    const branding: any = event?.branding || event
    let color = branding?.paletteColor
    if (!color || color.length > 7) {
        color = branding?.colorCode
    }
    if (!color || color.length > 7) {
        color = branding?.logoColor
    }
    const appColor = color || defaultPrimaryColor
    const txtColor = pickForeColor(appColor)
    document.documentElement.style.setProperty('--app-primary-color', appColor)
    document.documentElement.style.setProperty('--app-main-color1', shadeColor(appColor, 0.1))
    document.documentElement.style.setProperty('--app-main-color2', shadeColor(appColor, 0.25))
    document.documentElement.style.setProperty('--app-main-color3', shadeColor(appColor, 0.5))
    document.documentElement.style.setProperty('--app-main-color4', shadeColor(appColor, 0.75))
    document.documentElement.style.setProperty('--app-main-color5', shadeColor(appColor, 0.95))
    document.documentElement.style.setProperty('--app-main-color6', shadeColor(appColor, 0.4))
    document.documentElement.style.setProperty('--app-text-color', txtColor)
}

export const shadeColor = (color: string, opacity) => {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
    return color + _opacity.toString(16).toUpperCase()
}

export const isValidMongoId = (id: string) => {
    if (typeof id === 'string' && id?.match(/^[0-9a-fA-F]{24}$/)) {
        return true
    } else {
        return false
    }
}
