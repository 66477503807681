import { combineReducers } from 'redux'
import createReducer, { createActions } from '@app-store/slices/emailCredential/create'
import editReducer, { editActions } from '@app-store/slices/emailCredential/edit'
import listReducer, { listActions } from '@app-store/slices/emailCredential/list'
import getGoogleAuthURLReducer, { getGoogleAuthURLActions } from '@app-store/slices/emailCredential/getGoogleAuthUrl'
import oneEmailReducer, { oneEmailActions } from '@app-store/slices/emailCredential/oneEmailCredential'
import deleteEmailCredentialReducer, { deleteEmailCredentialActions } from './delete'
import checkSesAccountStatusReducer, { checkSesAccountStatusActions } from './checkSesAccountStatus'
import outlookAuthURLReducer, { outlookAuthURLActions } from './outlookAuthUrl'

export const emailCredentialReducer = combineReducers({
    create: createReducer,
    edit: editReducer,
    list: listReducer,
    getGoogleAuthUrl: getGoogleAuthURLReducer,
    oneEmail: oneEmailReducer,
    delete: deleteEmailCredentialReducer,
    checkSesAccountStatus: checkSesAccountStatusReducer,
    outlookAuthUrl: outlookAuthURLReducer
})

export const emailCredentialActions = {
    create: createActions,
    edit: editActions,
    list: listActions,
    getGoogleAuthUrl: getGoogleAuthURLActions,
    oneEmail: oneEmailActions,
    delete: deleteEmailCredentialActions,
    checkSesAccountStatus: checkSesAccountStatusActions,
    outlookAuthUrl: outlookAuthURLActions
}
