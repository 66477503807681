import createAvailabilitySaga, { createAvailabilityByTokenSaga } from './createAvailabilitySaga'
import editAvailabilitySaga, { editAvailabilityByTokenSaga } from './editAvailabilitySaga'
import oneAvailabilitySaga from './oneAvailabilitySaga'
import syncSaga from './sync'
import syncByTokenSaga from './synByToken'
import syncAuthenticateUrlSaga from './syncAuthenticateUrl'
import syncAuthenticateUrlByTokenSaga from './syncAuthenticateUrlByToken'
import getConnectedCalendarSaga, { getConnectedCalendarByTokenSaga } from './getConnectedCalendar'
import removeSyncSaga from './removeSync'
import listSaga from './listSaga'
import deleteSaga from './deleteSaga'
import listByToken from '@store/sagas/calendar/listByTokenSaga'
import removeSyncByTokenSaga from '@store/sagas/calendar/removeSyncByToken'

const calendarSagas = [
    createAvailabilitySaga(),
    editAvailabilitySaga(),
    oneAvailabilitySaga(),
    syncSaga(),
    syncAuthenticateUrlSaga(),
    getConnectedCalendarSaga(),
    removeSyncSaga(),
    listSaga(),
    deleteSaga(),
    syncAuthenticateUrlByTokenSaga(),
    syncByTokenSaga(),
    createAvailabilityByTokenSaga(),
    listByToken(),
    editAvailabilityByTokenSaga(),
    getConnectedCalendarByTokenSaga(),
    removeSyncByTokenSaga()
]

export default calendarSagas
