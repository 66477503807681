import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.campaigns.eventCampaignList

function* eventCampaignListSagaTask({ payload }) {
    try {
        const params = getParams(payload)
        const response = yield axios.get(`/notifications/campaigns/events`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* eventCampaignListSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, eventCampaignListSagaTask)
}

export default eventCampaignListSaga
