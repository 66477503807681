import { createSlice } from '@reduxjs/toolkit'

export interface IGeneralState {
    videoPlayerModalVisible: boolean
    saveSearchModalVisible: boolean
}

const initialState: IGeneralState = {
    videoPlayerModalVisible: false,
    saveSearchModalVisible: false
}

const generalSlice = createSlice({
    name: 'ui/general',
    initialState,
    reducers: {
        changeVideoPlayerModalVisiblity(state, action) {
            state.videoPlayerModalVisible = action.payload.visible || false
        },
        changeSaveSearchModalVisiblity(state, action) {
            state.saveSearchModalVisible = action.payload || false
        }
    }
})

export const generalActions = generalSlice.actions
export default generalSlice.reducer
