import { put, takeLatest } from 'redux-saga/effects'
import notification from 'antd/lib/notification'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { setEventCookie } from '@features/Auth'

interface TaskAction extends Action {
    payload: any
}

const { onRequest, onSuccess, onFailure } = rootActions.companies.checkStatusEvent
const { set, reset } = rootActions.companies.onboardingExhibitor

function* editorSagaTask({ payload }) {
    const event = payload.event
    try {
        const response = yield axios.get(`/companies/stats/onboarding-status/${event._id}`, {
            transformRequest: (data, headers) => {
                delete headers['event-id']
                return data
            }
        })
        if (response.success) {
            yield put(reset())
            yield put(
                set({
                    type: 'event',
                    value: event
                })
            )
            setEventCookie(event._id)
            yield put(onSuccess({ noSaveInStore: payload.noSaveInStore, ...response.result }))
            yield payload?.sagaCB?.onSuccess(response.result)
        } else if (response.error) {
            notification.error({
                message: response.error
            })
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* editorItpSaga(): any {
    yield takeLatest<TaskAction>(onRequest, editorSagaTask)
}

export default editorItpSaga
