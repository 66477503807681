import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISagaCb } from '@typings/requestPayloads'
import { initialState } from '@utils/constants/requestConstants'

export interface IAddCreditsPayload extends ISagaCb {
    subscriptionId: string
    data: {
        points: number
        description?: string
    }
}

const addCreditsSlice = createSlice({
    name: 'company-subscription/add-credits',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<IAddCreditsPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.data
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const addCreditsActions = addCreditsSlice.actions
export default addCreditsSlice.reducer
