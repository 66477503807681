import { STATUS } from '@features/General'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ILocalsPayload {
    bundle?: string[]
    extraKeys?: string[]
    language?: string
}

export interface ILocalsState {
    status: string
    data: any
    errors: any
}

const initialState: ILocalsState = {
    status: STATUS.IDLE,
    data: {},
    errors: null
}
const locals = createSlice({
    name: 'localization/locals',
    initialState,
    reducers: {
        onRequest(state, action: PayloadAction<ILocalsPayload>) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const localsActions = locals.actions
export default locals.reducer
