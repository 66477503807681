import uniq from 'lodash/uniq'
import { toggleValueInArray } from '@features/General/handlers'
import cloneDeep from 'lodash/cloneDeep'
import { STATUS } from '@features/General'

export const sideEffectReducers = {
    onSuccess(state, action) {
        state.status = STATUS.READY
        state.data = action.payload
    },
    onFailure(state, action) {
        state.status = STATUS.ERROR
        state.errors = action.payload
    }
}

export const listSideEffectReducers = {
    onSuccess(state, action) {
        state.status = STATUS.READY
        if (action.payload?.noSaveInStore !== true) {
            state.data = action.payload.data || []
            state.pagination = {
                ...state.pagination,
                skip: action.payload.skip,
                total: action.payload.total
            }
        }
    },
    onFailure(state, action) {
        state.status = STATUS.ERROR
        state.errors = action.payload
    }
}

export const manipulateListReducers = {
    onAddToList(state, action) {
        const newData = [...state.data]
        newData.push(action.payload)
        state.data = newData
    },
    onRemoveFromList(state, action) {
        let newData = [...state.data]
        newData = newData.filter(item => item._id !== action.payload)
        state.data = newData
    },
    onEditRecordInList(state, action) {
        const newData = [...state.data]
        const record = newData.find(item => item._id === action.payload.id)
        if (record) {
            Object.keys(action.payload.data).forEach(key => {
                record[key] = action.payload.data[key]
            })
        }
        state.data = newData
    }
}

export const listUIReducers = {
    onChangePagination(state, action) {
        state.pagination = { ...state.pagination, ...action.payload }
    },
    onChangeSelectedRows(state, action) {
        state.selectedRowKeys = action.payload
    },
    onChangeHidedColumns(state, action) {
        state.hiddenColumns = action.payload
    },
    onChangeItem(state, action) {
        state[action.payload.key] = action.payload.value
    },
    onChangeSearch(state, action) {
        state.searchValue = action.payload
    },
    onChangeTotal(state, action) {
        state.pagination = { ...state.pagination, total: action.payload }
    },
    onChangeFilters(state, action) {
        state.filters = action.payload
    },
    onAddFilter(state, action) {
        state.filters = { ...state.filters, ...action.payload }
    },
    onChangePureFilters(state, action) {
        state.pureFilters = action.payload
    },
    onAddPureFilter(state, action) {
        state.pureFilters = { ...state.pureFilters, ...action.payload }
    },
    addPureFilter(state, action) {
        const objectkeys = Object.keys(action.payload)
        for (const key of objectkeys) {
            state.pureFilters[key] = action.payload[key]
        }
    },
    onRemoveFilter(state, action) {
        const newFilters = { ...state.filters }
        delete newFilters?.[action.payload]
        state.filters = newFilters
    },
    onRemovePureFilter(state, action) {
        const newFilters = { ...state.pureFilters }
        delete newFilters?.[action.payload]
        state.pureFilters = newFilters
    },
    onResetFilters(state) {
        state.filters = {}
        const newPopulates = cloneDeep(state.populates)
        newPopulates.filter(x => x.match).forEach(item => delete item.match)
        state.populates = newPopulates
        state.pureFilters = {}
    },
    onResetData(state) {
        state.pagination = {
            itemsPerPage: 10,
            currentPage: 1,
            total: 0
        }
        state.searchValue = null
        state.filters = {}
        const newPopulates = cloneDeep(state.populates)
        newPopulates.filter(x => x.match).forEach(item => delete item.match)
        state.populates = newPopulates
        state.pureFilters = {}
        // state.sort = '-createdAt'
    }
}

export const directoryReducers = {
    toggleExpandedRowKey(state, action) {
        state.expandedRowKeys = toggleValueInArray(state.expandedRowKeys, action.payload)
    },
    addExpandedRowKey(state, action) {
        state.expandedRowKeys = uniq([...state.expandedRowKeys, action.payload])
    },
    resetExpandedRowKeys(state) {
        state.expandedRowKeys = []
    },
    toggleExpandedContactKey(state, action) {
        state.expandedContactKeys = toggleValueInArray(state.expandedContactKeys, action.payload)
    },
    addExpandedContactKey(state, action) {
        state.expandedContactKeys = uniq([...state.expandedContactKeys, action.payload])
    },
    toggleExpandedContactTreeKey(state, action) {
        state.expandedContactTreeKeys = toggleValueInArray(state.expandedContactTreeKeys, action.payload)
    },
    addMatchToPopulate(state, action) {
        const newPopulates = cloneDeep(state.populates)
        const populate = newPopulates.find(item => item.path === action.payload.key)
        populate.match = action.payload.value
        state.populates = newPopulates
    },
    removeMatchFromPopulate(state, action) {
        const newPopulates = cloneDeep(state.populates)
        const populate = newPopulates.find(item => item.path === action.payload.key)
        delete populate.match
        state.populates = newPopulates
    }
}
