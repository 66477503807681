import DownloadCloudLineIcon from 'remixicon-react/DownloadCloudLineIcon'
import SendPlaneLineIcon from 'remixicon-react/SendPlaneLineIcon'
import FileExcel2LineIcon from 'remixicon-react/FileExcel2LineIcon'
import AddCircleLineIcon from 'remixicon-react/AddCircleLineIcon'
import CalendarCheckLineIcon from 'remixicon-react/CalendarCheckLineIcon'
import { IButtons } from '@features/General'
import { history } from '@components/App'
import routes from '@utils/constants/routes'
import { onDownload, onDownloadExcel, onSend } from '@features/Meeting/MeetingAgenda'

export const listButtons = (
    companyId,
    i18n,
    isMeyAgenda = false,
    listFilters,
    setSendModalVisible,
    showDownloadExcel
): IButtons[] => [
    {
        key: 'download',
        type: 'secondary-ghost-icon',
        icon: DownloadCloudLineIcon,
        onClick: () => onDownload(companyId, listFilters),
        align: 'left',
        showWithData: true
    },
    {
        align: 'left',
        key: 'downloadExcel',
        type: 'secondary-ghost-icon',
        icon: FileExcel2LineIcon,
        onClick: () => onDownloadExcel(companyId, listFilters),
        showWithData: true,
        hide: !showDownloadExcel
    },
    {
        key: 'send',
        type: 'secondary-ghost-icon',
        icon: SendPlaneLineIcon,
        onClick: () => {
            if (isMeyAgenda) {
                onSend(companyId, listFilters, () => undefined, i18n)
            } else {
                setSendModalVisible(true)
            }
        },
        align: 'left',
        showWithData: true
    },
    {
        key: 'CreatePastMeeting',
        type: 'primary-solid',
        title: i18n?.Meeting?.On_the_spot_meeting || 'On-the-spot meeting',
        icon: AddCircleLineIcon,
        abilityService: 'Meeting',
        showAsDropDown: true,
        tooltip: i18n?.Meeting?.Log_onsite_meeting_place || 'Log an onsite meeting that has taken place.',
        onClick: () => history.push(routes.meetings.meeting_agenda.add._),
        hide: !!companyId || isMeyAgenda
    },
    {
        key: 'CreateNewMeeting',
        type: 'primary-solid',
        title: i18n?.Meeting?.['New Meeting'],
        icon: CalendarCheckLineIcon,
        abilityService: 'Meeting',
        showAsDropDown: true,
        tooltip: 'Schedule a future meeting',
        onClick: () => history.push(routes.meetings.meeting_agenda.create._),
        hide: !!companyId || isMeyAgenda
    }
]
