import { STATUS } from '@features/General'
import { createSlice } from '@reduxjs/toolkit'
import { listInitialState } from '@utils/constants/requestConstants'

const getUnreadNumbers = createSlice({
    name: 'notification/get-unread-numbers',
    initialState: listInitialState,
    reducers: {
        onRequest(state) {
            state.status = STATUS.RUNNING
        },
        onSuccess(state, action) {
            state.status = STATUS.READY
            state.data = action.payload.total
        },
        onReduce(state) {
            state.data = state.data - 1 < 0 ? 0 : state.data - 1
        },
        onFailure(state, action) {
            state.status = STATUS.ERROR
            state.errors = action.payload
        }
    }
})

export const getUnreadNumbersActions = getUnreadNumbers.actions
export default getUnreadNumbers.reducer
