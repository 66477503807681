import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isoWeek from 'dayjs/plugin/isoWeek'
import { rootActions } from '@app-store/slices'
import store from '@app-store/store'
import { isValidMongoId } from '@utils/helpers/commonHelpers'

dayjs.extend(utc)
dayjs.extend(isoWeek)

export const callAvailableSlotsEndpoint = (
    hostCompanyId,
    guestCompanyId,
    guestIds,
    hostIds,
    isMeetingHubEvent,
    week,
    hallId,
    offsetTime
) => {
    if (guestIds && hostIds) {
        const payload: any = {
            hostCompanyId,
            hostIds,
            guestIds,
            guestCompanyId
        }
        if (isValidMongoId(hallId)) {
            payload.hallId = hallId
        }
        if (isMeetingHubEvent) {
            const startDate =
                week === 0
                    ? dayjs().utc()
                    : dayjs.utc(dayjs.utc().utcOffset(offsetTime).startOf('isoWeek').format()).add(week, 'weeks')
            const endDate = dayjs.utc(dayjs.utc().utcOffset(offsetTime).endOf('isoWeek').format()).add(week, 'weeks')
            payload.userStartDate = startDate.format()
            if (endDate.diff(startDate, 'seconds', true) < 0) {
                payload.userEndDate = startDate.format()
                payload.userStartDate = endDate.format()
            } else {
                payload.userEndDate = endDate.format()
            }
        }
        store.dispatch(rootActions.meetings.getAvailableSlots.onRequest(payload))
    }
}
