import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.notification.getUnreadNumbers

function* getUnreadNumbersSagaTask() {
    try {
        const response = yield axios.get(`/notifications`, {
            params: { query: { type: 'System', status: 'Pending' }, limit: 1, hasTotal: true }
        })
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* getUnreadNumbersSaga(): any {
    yield takeLatest<ListTaskAction>(onRequest, getUnreadNumbersSagaTask)
}

export default getUnreadNumbersSaga
