import { rootActions } from '@store/slices'
import { IRevokeEventAccessPayload } from '@app-store/slices/users/revokeEventAccess'
import axios from '@utils/request'
import { Action } from 'redux'
import notification from 'antd/lib/notification'
import { put, takeLatest } from 'redux-saga/effects'

interface TaskAction extends Action {
    payload: IRevokeEventAccessPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.users.revokeEventAccess

function* revokeEventAccessSagaTask({ payload }) {
    try {
        const response = yield axios.get(`/users/revoke-event-access/${payload.id}`)
        if (response.success) {
            notification.success({
                message: `Access of ${payload.email} has been revoked to this event successfully.`
            })
            yield put(onSuccess(response.result))
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* revokeEventAccessSaga(): any {
    yield takeLatest<TaskAction>(onRequest, revokeEventAccessSagaTask)
}

export default revokeEventAccessSaga
