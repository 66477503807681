import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios, { getParams } from '@utils/request'
import { IGetUnavailableSlotsByTokenPayload } from '@app-store/slices/meetings/getUnavailableSlotsByToken'

interface TaskAction extends Action {
    payload: IGetUnavailableSlotsByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetings.getUnavailableSlotsByToken

function* getUnavailableSlotsByTokenTask({ payload }) {
    const params = yield getParams(payload)
    try {
        const response = yield axios.get(`/meeting/unavailable/by-token?token=${payload.token}`, {
            params
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload.sagaCB) {
                yield payload.sagaCB.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        if (typeof payload?.sagaCB?.onError === 'function') {
            payload.sagaCB.onError()
        }
        yield put(onFailure(''))
    }
}

function* getUnavailableSlotsByToken(): any {
    yield takeLatest<TaskAction>(onRequest, getUnavailableSlotsByTokenTask)
}

export default getUnavailableSlotsByToken
