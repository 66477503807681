import React, { useEffect } from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import store from '@app-store/store'
import useUser from '@hooks/processor/useUser'
import IntlWrapper from './IntlWrapper'
import { Provider } from 'react-redux'
import PrivateRoute, { FlatRouterProvider } from '@UI/PrivateRoute'
import routes from '@utils/constants/routes'
import loadable from '@loadable/component'
import PageLoading from '@UI/PageLoading'
import { applyPermissions } from '@utils/helpers/applyPermissionsHelper'
export const loadableOptions = { fallback: <PageLoading /> }
export const history = createBrowserHistory()

const GlobalModalsProvider = loadable(() => import('./GlobalModalsProvider'), loadableOptions)
const LayoutProvider = loadable(() => import('@components/Layouts/LayoutProvider/Provider'), loadableOptions)
const Error = loadable(() => import('@components/Error'), loadableOptions)
const Login = loadable(() => import('@pages/Auth/Login'), loadableOptions)
const InvalidSubDomain = loadable(() => import('@pages/Auth/InvalidSubDomain'), loadableOptions)
const OtpLogin = loadable(() => import('@pages/Auth/LoginWithOtp'), loadableOptions)
const Support = loadable(() => import('@components/Auth/Support'), loadableOptions)
const Activation = loadable(() => import('@pages/Auth/Activation'), loadableOptions)
const Confirmation = loadable(() => import('@pages/Auth/Confirmation'), loadableOptions)
const NewPassword = loadable(() => import('@pages/Auth/NewPassword'), loadableOptions)
const Registration = loadable(() => import('@pages/Auth/Register'), loadableOptions)
const ActivationSignup = loadable(() => import('@pages/Auth/ActivationSignUp'), loadableOptions)
const SubscriptionSignUp = loadable(() => import('@pages/Auth/SubscriptionRegister'))
const SubscriptionLogin = loadable(() => import('@components/Auth/LoginForm/MeetingHub'))
const PublicSignUp = loadable(() => import('@pages/Auth/PublicSignUp'), loadableOptions)
const Integrations = loadable(() => import('@pages/Integrations'), loadableOptions)
const ForgotPassword = loadable(() => import('@pages/Auth/ForgotPassword'), loadableOptions)
const InvitationAuth = loadable(() => import('@pages/Auth/Invitation'), loadableOptions)
const Organizer = loadable(() => import('@pages/Organizer'), loadableOptions)
const Exhibitor = loadable(() => import('@pages/Exhibitor'), loadableOptions)
const OpenEvents = loadable(() => import('@pages/OpenEvents'), loadableOptions)
const Matching = loadable(() => import('@pages/Matching'), loadableOptions)
const SyncCalendarByToken = loadable(() => import('@pages/SyncCalendarByToken'), loadableOptions)
const SuccessfulCalendarByToken = loadable(
    () => import('@pages/SyncCalendarByToken/SuccessfulCalendar'),
    loadableOptions
)
const MatchesByToken = loadable(() => import('@pages/Matching/MatchesByToken'), loadableOptions)
const MatchingSyncCalendarByToken = loadable(() => import('@pages/Matching/SyncCalendarByToken'), loadableOptions)
const AvailabilityCalendarByToken = loadable(
    () => import('@pages/Matching/AvailabilityCalendarByToken'),
    loadableOptions
)
const MatchingSuccessfulCalendarByToken = loadable(
    () => import('@pages/Matching/SuccessfulCalendarByToken'),
    loadableOptions
)
const Settings = loadable(() => import('@pages/Settings'), loadableOptions)
const Subscription = loadable(() => import('@pages/Subscription'), loadableOptions)
const Pipeline = loadable(() => import('@pages/Pipeline'), loadableOptions)
const DatabaseImport = loadable(() => import('@pages/DatabaseImport'), loadableOptions)
const ImportItp = loadable(() => import('@pages/ItpImport'), loadableOptions)
const ImportDemoData = loadable(() => import('@pages/ImportDemoData'), loadableOptions)
const MeetingRequest = loadable(() => import('@pages/MeetingRequest'), loadableOptions)
const JoinAladdinb2b = loadable(() => import('@pages/JoinAladdinb2b'), loadableOptions)
const Analytics = loadable(() => import('@pages/Analytics'), loadableOptions)
const CRMIntegration = loadable(() => import('@pages/CRMIntegration'), loadableOptions)
const Directory = loadable(() => import('@pages/Directory'), loadableOptions)
const Meetings = loadable(() => import('@pages/Meetings'), loadableOptions)
const ALarms = loadable(() => import('@pages/Alarms'), loadableOptions)

const NotInterestedMeeting = loadable(
    () => import('@pages/Settings/Communications/Campaign/MeetingRequest/NotInterested'),
    loadableOptions
)
const SurveyByToken = loadable(() => import('@pages/Settings/Communications/Survey/FeedbackByToken'), loadableOptions)
const MeetingCalendar = loadable(
    () => import('@pages/Settings/Communications/Campaign/MeetingRequest/Calendar'),
    loadableOptions
)
const WhatsNewDetails = loadable(() => import('@pages/Settings/Communications/WhatsNew/Details'), loadableOptions)
const CompanyBadgesPrintPreview = loadable(
    () => import('@pages/Settings/Event/CompanyBadges/PrintPreview'),
    loadableOptions
)
const MeetingInfoByToken = loadable(() => import('@components/Meetings/MeetingProfile'), loadableOptions)
const ITPProfileByToken = loadable(() => import('@pages/Directory/CompanyProfile/ITP/Profile'), loadableOptions)
const Unsubscribe = loadable(() => import('@pages/Unsubscribe'), loadableOptions)
const AppWrapper: React.FC = ({ children }) => <div style={{ overflow: 'auto' }}>{children}</div>

function App() {
    const { appDirection } = useUser()
    useEffect(() => {
        if (appDirection === 'rtl') {
            document.body.className += 'rtl'
        }
    }, [])

    useEffect(() => {
        applyPermissions(null)
    }, [])

    return (
        <Provider store={store}>
            <IntlWrapper>
                <Router history={history}>
                    <AppWrapper>
                        <LayoutProvider>
                            <GlobalModalsProvider />
                            <Switch>
                                <Route exact path={routes._} render={() => <FlatRouterProvider />} />
                                <Route exact path={routes.login._} component={Login} />
                                <Route exact path={routes.invalid_sub_domain._} component={InvalidSubDomain} />
                                <Route
                                    path={routes.meetings.wish_campaign.meeting_request.not_interested._}
                                    component={NotInterestedMeeting}
                                />
                                <Route path={routes.unsubscribe._} component={Unsubscribe} />
                                <Route
                                    path={routes.settings.communications.send_notifications.survey_feedback_by_token._}
                                    component={SurveyByToken}
                                />
                                <Route
                                    path={routes.meetings.wish_campaign.meeting_request.calendar._}
                                    component={MeetingCalendar}
                                />
                                <Route
                                    path={routes.settings.communications.whats_new.detail._}
                                    component={WhatsNewDetails}
                                />
                                <Route
                                    path={routes.settings.event.company_badges.preview._}
                                    component={CompanyBadgesPrintPreview}
                                />
                                <Route exact path={routes.support._} component={Support} />
                                <Route exact path={routes.activation._} component={Activation} />
                                <Route exact path={routes.itp._} render={() => <Activation itpActivation />} />
                                <Route exact path={routes.login.confirmation._} component={Confirmation} />
                                <Route exact path={routes.login.new_password._} component={NewPassword} />
                                <Route exact path={routes.join_aladdinb2b._} component={JoinAladdinb2b} />
                                <Route path={routes.sign_up._} component={Registration} />
                                <Route path={routes.activation_sign_up._} component={ActivationSignup} />
                                <Route path={routes.subscription.signup._} component={SubscriptionSignUp} />
                                <Route path={routes.subscription.login._} component={SubscriptionLogin} />
                                <Route path={routes.login.otp._} component={OtpLogin} />
                                <Route path={routes.forgot_password._} component={ForgotPassword} />
                                <Route path={routes.open_events._} component={OpenEvents} />
                                <Route path={routes.invitation._} component={InvitationAuth} />
                                <Route path={routes.public.sign_up._} component={PublicSignUp} />
                                <Route path={routes.integrations._} component={Integrations} />
                                <Route path={routes.meeting_request._} component={MeetingRequest} />
                                <Route path={routes.matching.match_list_select._} component={MatchesByToken} />
                                <Route path={routes.sync_calendar._} component={SyncCalendarByToken} />
                                <Route
                                    path={routes.sync_calendar.successful_calendar._}
                                    component={SuccessfulCalendarByToken}
                                />
                                <Route
                                    path={routes.matching.match_list_select.sync_calendar._}
                                    component={MatchingSyncCalendarByToken}
                                />
                                <Route
                                    path={routes.matching.match_list_select.availability_calendar._}
                                    component={AvailabilityCalendarByToken}
                                />
                                <Route
                                    path={routes.matching.match_list_select.successful_calendar._}
                                    component={MatchingSuccessfulCalendarByToken}
                                />
                                <Route path={routes.meetings.meetingInfo._} component={MeetingInfoByToken} />
                                <Route path={routes.itp.itp_profile._} component={ITPProfileByToken} />
                                <Route path={routes.exhibitor._} component={Exhibitor} />
                                <Route path={routes.subscription._} component={Subscription} />
                                <PrivateRoute path={routes.matching._} component={Matching} />
                                <PrivateRoute path={routes.organizer._} component={Organizer} />
                                <PrivateRoute path={routes.settings._} component={Settings} />
                                <PrivateRoute path={routes.meetings._} component={Meetings} />
                                <PrivateRoute path={routes.pipeline._} component={Pipeline} />
                                <PrivateRoute path={routes.database_import._} component={DatabaseImport} />
                                <PrivateRoute path={routes.itp_import._} component={ImportItp} />
                                <PrivateRoute path={routes.import_demo_data._} component={ImportDemoData} />
                                <PrivateRoute path={routes.directory._} component={Directory} />
                                <PrivateRoute path={routes.alarms._} component={ALarms} />
                                <PrivateRoute path={routes.crm._} component={CRMIntegration} />
                                <PrivateRoute path={routes.analytics._} component={Analytics} />

                                <Route path="*" component={Error} />
                            </Switch>
                        </LayoutProvider>
                    </AppWrapper>
                </Router>
            </IntlWrapper>
        </Provider>
    )
}

export default App
