import dots from '@assets/images/patterns/dots.jpeg'
import mosaic from '@assets/images/patterns/mosaic.png'
import triangleMosaic from '@assets/images/patterns/triangle-mosaic.png'
import webb from '@assets/images/patterns/webb.png'

export const patternImages = [dots, mosaic, triangleMosaic, webb]
export const paletteColors = ['#e9e9e9', '#00a3ff', '#e1473d', '#ff681a', '#22b354', '#0074b5', '#ae1ac6']

export const CHART_CONFIG = {
    BAR_THICKNESS: 70,
    CUT_OUT: 50,
    MAIN_COLOR: '#ff681a',
    BG_COLOR: '#0097EE',
    USER_BG_COLOR: '#DEEDFF',
    BG_COLOR_3: '#FFC22D'
}

export enum USER_TYPES_IN_EVENT {
    INVESTOR = 'investor',
    SPONSOR = 'sponsor',
    EVENT_ORGANIZER = 'event_organizer',
    GOVERNMENT_ENTITY = 'governemnt_entity',
    COMPANY = 'company',
    HOST = 'host',
    GUEST = 'guest',
    EXHIBITOR = 'exhibitor',
    VISITOR = 'visitor'
}

export enum USER_TYPES {
    ADMIN = 'Admin',
    ORGANIZER = 'Organizer',
    USER = 'User'
}
export enum USER_ROLES {
    ADMIN = 'Admin',
    TEAM_MANAGER = 'Manager',
    OPERATION = 'Operation',
    EDITOR = 'Editor',
    ONGROUND = 'Onground',
    USER = 'User',
    VIEWER = 'Viewer'
}

export enum ITP_TYPES {
    ENTERPRISE = 'Enterprise',
    STARTUP = 'Startup',
    INVESTOR = 'Investor'
}

export enum ImportStatus {
    PENDING = 'Pending',
    FAILED = 'Failed',
    PARTLY_FINISHED = 'PartlyFinished',
    FINISHED = 'Finished'
}

export enum UserStatuses {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive'
}

export enum CampaignStatuses {
    PENDING = 'Pending',
    ONGOING = 'Ongoing',
    CONDUCTED = 'Conducted',
    STOPPED = 'Stopped',
    PAUSED = 'Paused'
}

export enum PropertyRelatedRecordType {
    Product = 'Product',
    Service = 'Service',
    Company = 'Company',
    Contact = 'Contact',
    Meeting = 'Meeting',
    MeetingRequest = 'MeetingRequest',
    SharedList = 'SharedList'
}

export enum ProperyVisibilityType {
    Public = 'Public',
    Private = 'Private',
    Internal = 'Internal'
}

export enum PropertyType {
    Note = 'Note',
    Task = 'Task',
    Image = 'Image',
    Document = 'Document',
    Video = 'Video'
}
export enum ProfileUserTypes {
    ADMIN = 'Super_Admin',
    ADMIN_ROLE = 'Admin_Role',
    USER = 'User',
    EMPLOYEE = 'Employee',
    PAYING_SUBSCRIBER = 'Paying_Subscriber',
    NON_PAYING_SUBSCRIBER = 'Non_Paying_Subscriber',
    LOGGED_IN_USER = 'Logged_In_User',
    FLOW_WORKER = 'Flow_Worker'
}

export enum NotificationRecordTypes {
    COMPANY = 'Company',
    CONTACT = 'Contact',
    MEETING = 'Meeting',
    MEETING_REQUEST = 'MeetingRequest',
    PRODUCT = 'Product',
    SERVICE = 'Service',
    RFQ = 'RFQ',
    QUOTATION = 'Quotation',
    SHARED_LIST = 'SharedList'
}

export enum RFQsStatusTypes {
    QUOTED = 'Quoted',
    INACTIVE = 'Inactive',
    ACTIVE = 'Active',
    OPENED = 'Opened',
    NEW = 'New',
    CLOSED = 'Closed',
    EXPIRED = 'Expired'
}

export enum QuotationStatusTypes {
    OPENED = 'Opened',
    DECLINED = 'Declined',
    QUOTED = 'Quoted',
    REVOKED = 'Revoked',
    ACCEPTED = 'Accepted'
}

export enum EmailCredentialProviders {
    GMAIL = 'Gmail',
    OUTLOOK = 'Outlook'
}

export enum EmailSetupSendersProviders {
    GMAIL = 'Gmail',
    OUTLOOK = 'Outlook',
    SMTP = 'SMTP',
    SES = 'SES'
}

export enum SubscriptionCategory {
    PROFESSIONAL = 'Professional',
    PREMIUM = 'Premium',
    CUSTOM = 'Custom'
}

export const NAVIGATION_POSTION = {
    TOP: 'top',
    SIDE: 'side'
}
export const NOTIFICATION_TYPES = {
    EMAIL: 'Email',
    PUSH_NOTIFICATION: 'PushNotification',
    SMS: 'SMS',
    SYSTEM: 'System'
}

export const NOTIFICATION_EVENTS = {
    INFORMATIONAL_MESSAGE: 'InformationMessage',
    NEW_REQUEST_MEETING: 'NewRequestMeeting',
    ACCEPT_REQUEST_MEETING: 'AcceptRequestMeeting',
    REJECT_REQUEST_MEETING: 'RejectRequestMeeting',
    RESCHEDULE_REQUEST_MEETING: 'RescheduleRequestMeeting',
    CANCEL_REQUEST_MEETING: 'CancelRequestMeeting',
    UPDATE_REQUEST_MEETING: 'UpdateRequestMeeting',
    CONFERENCE_AGENDA_SESSION_REMINDER: 'ConferenceAgendaSessionReminder',
    SURVEY_NOTIFICATION: 'SurveyNotificaiton',
    MEETING_START_SOON: 'MeetingStartSoon',
    SCHEDULE_MEETING: 'ScheduleMeeting',
    RESCHEDULE_MEETING: 'RescheduleMeeting',
    CANCEL_MEETING: 'CancelMeeting',
    REMINDER_MESSAGE: 'ReminderMessage'
}

import CommunityLineIcon from 'remixicon-react/CommunityLineIcon'
import GlobalLineIcon from 'remixicon-react/GlobalLineIcon'
import LockLineIcon from 'remixicon-react/LockLineIcon'
import GovernmentLineIcon from 'remixicon-react/GovernmentLineIcon'
import ServiceLineIcon from 'remixicon-react/ServiceLineIcon'
import UserSearchLineIcon from 'remixicon-react/UserSearchLineIcon'
import BookMarkLineIcon from 'remixicon-react/BookmarkLineIcon'
import Building3LineIcon from 'remixicon-react/Building3LineIcon'
import StockLineIcon from 'remixicon-react/StockLineIcon'
import EarthLineIcon from 'remixicon-react/EarthLineIcon'
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon'
import TeamFillIcon from 'remixicon-react/TeamFillIcon'
import HandHeartLineIcon from 'remixicon-react/HandHeartLineIcon'
import Store2LineIcon from 'remixicon-react/Store2LineIcon'
import QuestionLineIcon from 'remixicon-react/QuestionLineIcon'
import CustomerServiceLineIcon from 'remixicon-react/CustomerServiceLineIcon'

export const TARGET_TYPE_ICONS: any = {
    'Semi-Gov': CommunityLineIcon,
    Public: GlobalLineIcon,
    Private: LockLineIcon,
    'Government_Ministry/Bureau/Commission': GovernmentLineIcon,
    Association: ServiceLineIcon,
    Agent: UserSearchLineIcon,
    Education: BookMarkLineIcon,
    Manufacturer: Building3LineIcon,
    Trading_Company: StockLineIcon,
    'Distributor/Wholesales': EarthLineIcon,
    Buying_Office: BuildingLineIcon,
    Conglomerate: TeamFillIcon,
    Non_Profit: HandHeartLineIcon,
    Retailer: Store2LineIcon,
    Business_Service: CustomerServiceLineIcon,
    Others: QuestionLineIcon
}

export const NOTIFICATION_STATUSES = {
    PENDING: 'Pending',
    RECEIVED: 'Received',
    FAILED: 'Failed',
    READ: 'Read'
}

export const EMAIL_REGX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
