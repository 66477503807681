import { all } from '@redux-saga/core/effects'
import authSaga from './auth'
import campaignsSaga from './campaigns'
import emailCredentialSaga from './emailCredential'
import contactsSaga from './contacts'
import companiesSaga from './companies'
import chatDetailsSagas from './chatDetails'
import chatSagas from './chat'
import groupSaga from './group'
import usersSaga from './users'
import rolesPermissionsSaga from './rolesPermissions'
import emailsSaga from './emails'
import meetingAgendaSaga from './meetingAgenda'
import meetingsSaga from './meetings'
import notificationsSaga from './notifications'
import hallSaga from './hall'
import tableSaga from './table'
import eventSettingSaga from './eventSettings'
import matchListsSaga from './matchLists'
import matchListGroupsSaga from './matchListGroups'
import sharedListsSaga from './sharedLists'
import countriesSaga from './countries'
import citiesSaga from './cities'
import eventsSaga from './events'
import categoriesSaga from './categories'
import eventTypeSaga from './event-type'
import propertiesSaga from './propertis'
import sourceCategoriesSagas from './sourceCategories'
import dataImport from './dataImport'
import onboardingTemplates from './onboardingTemplates'
import onboardingOrganizer from './onboardingOrganizer'
import meetingRequestsSaga from './meetingRequests'
import eventTopicsSaga from './eventTopics'
import conferencesSaga from './conferences'
import sessionsSaga from './sessions'
import speakersSaga from './speakers'
import editListSaga from './editList'
import wishLists from './wishLists'
import tags from './tags'
import companyStatistics from './companyStatistics'
import userStatistics from './userStatistics'
import meetingStatisticsSagas from './meetingStatistics'
import productsSaga from './products'
import servicesSaga from './services'
import momentumSagas from './momentum'
import scansSagas from './scans'
import rfqsSagas from './rfqs'
import quotationsSagas from './quotations'
import surveySagas from './survey'
import integrationServicesSagas from './integrationServices'
import crmSagas from './crm'
import templateSagas from './templates'
import localizationSaga from './localization'
import subscriptionsSaga from './subscription'
import subscriptionOrdersSaga from './subscriptionOrder'
import subscriptionPaymentSaga from './subscriptionPayments'
import supportSaga from './support'
import reportingSaga from './reporting'
import badgesSaga from './badges'
import rfqsSaga from './rfqs'
import quotationsSaga from './quotations'
import toursSaga from './tour'
import companySubscriptionsSaga from './companySubscription'
import demosSagas from './demos'
import transactionSagas from './transactions'
import linkedInAccount from './linkedInAccount'
import auditTrailsSagas from './auditTrails'
import callLogSagas from './callLog'
import calendarSagas from './calendar'
import newFeatureSagas from './newFeature'
import moduleSagas from './module'
import labelTagSagas from './labelTag'
import qrSagas from './qr'
import featureSagas from './feature'
import eventSubscriptionsSaga from './eventSubscription'
import unsubscribeSagas from './unSubscribe'
import perfectMatchSagas from './perfectMatch'

function* rootSaga() {
    yield all([
        ...authSaga,
        ...campaignsSaga,
        ...emailCredentialSaga,
        ...contactsSaga,
        ...companiesSaga,
        ...chatDetailsSagas,
        ...chatSagas,
        ...groupSaga,
        ...usersSaga,
        ...emailsSaga,
        ...meetingAgendaSaga,
        ...meetingsSaga,
        ...notificationsSaga,
        ...hallSaga,
        ...eventSettingSaga,
        ...countriesSaga,
        ...citiesSaga,
        ...eventsSaga,
        ...categoriesSaga,
        ...eventTypeSaga,
        ...tableSaga,
        ...eventSettingSaga,
        ...propertiesSaga,
        ...matchListsSaga,
        ...matchListGroupsSaga,
        ...sharedListsSaga,
        ...sourceCategoriesSagas,
        ...dataImport,
        ...onboardingOrganizer,
        ...eventTopicsSaga,
        ...conferencesSaga,
        ...sessionsSaga,
        ...speakersSaga,
        ...wishLists,
        ...editListSaga,
        ...meetingRequestsSaga,
        ...rolesPermissionsSaga,
        ...tags,
        ...productsSaga,
        ...servicesSaga,
        ...companyStatistics,
        ...meetingStatisticsSagas,
        ...userStatistics,
        ...momentumSagas,
        ...scansSagas,
        ...rfqsSagas,
        ...quotationsSagas,
        ...surveySagas,
        ...integrationServicesSagas,
        ...crmSagas,
        ...templateSagas,
        ...localizationSaga,
        ...subscriptionsSaga,
        ...subscriptionOrdersSaga,
        ...supportSaga,
        ...reportingSaga,
        ...badgesSaga,
        ...rfqsSaga,
        ...quotationsSaga,
        ...toursSaga,
        ...companySubscriptionsSaga,
        ...demosSagas,
        ...subscriptionPaymentSaga,
        ...transactionSagas,
        ...linkedInAccount,
        ...auditTrailsSagas,
        ...callLogSagas,
        ...calendarSagas,
        ...newFeatureSagas,
        ...moduleSagas,
        ...labelTagSagas,
        ...qrSagas,
        ...featureSagas,
        ...eventSubscriptionsSaga,
        ...unsubscribeSagas,
        ...perfectMatchSagas,
        onboardingTemplates()
    ])
}

export default rootSaga
