import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { TokenListTaskAction } from '@typings/requestPayloads'

const { onRequest, onSuccess, onFailure } = rootActions.categories.listByToken

function* listByTokenSagaTask({ payload }: TokenListTaskAction) {
    try {
        const params: any = {
            ...payload.pagination
        }
        if (payload.query) {
            params.query = payload.query
        }
        const response = yield axios.get(`/companies/categories/by-token/${payload.token}`, {
            params
        })
        if (response.success) {
            yield put(onSuccess({ ...response.result }))
            yield payload.sagaCB?.onSuccess(response.result.data)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* listByTokenSaga(): any {
    yield takeLatest<TokenListTaskAction>(onRequest, listByTokenSagaTask)
}

export default listByTokenSaga
