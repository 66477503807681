import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getOffsetTime } from '@features/General/dayjs/hanlers'
export interface IMatchesByTokenDataItems {
    selectedCompanies?: string[]
    rejectedCompanies?: string[]
    selectedSlots?: string[]
    timezone?: string
}

export interface IMatchesByTokenFlowMode {
    token?: string | null
}

export interface IMatchesByTokenUIState extends IMatchesByTokenFlowMode {
    data: IMatchesByTokenDataItems
    token: null
}

const initialState: IMatchesByTokenUIState = {
    data: {
        selectedCompanies: [],
        rejectedCompanies: [],
        selectedSlots: [],
        timezone: getOffsetTime(null)
    },
    token: null
}

const matchesByTokenSlice = createSlice({
    name: 'ui/select-match-list',
    initialState,
    reducers: {
        addItems(state, action: PayloadAction<IMatchesByTokenDataItems>) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state.data[item] = action.payload[item]
            }
        },
        setMode(state, action: PayloadAction<IMatchesByTokenFlowMode>) {
            const objectkeys = Object.keys(action.payload)
            for (const item of objectkeys) {
                state[item] = action.payload[item]
            }
        }
    }
})

export const matchesByTokenActions = matchesByTokenSlice.actions
export default matchesByTokenSlice.reducer
