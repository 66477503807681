import React from 'react'
import notification from 'antd/lib/notification'
import { removeUserAuthCookie } from '@features/Auth'
import isEmpty from 'lodash/isEmpty'
import Axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { COOKIE_KEYS, getCookie } from '@features/General'
import { awsConfig } from 'awsConfig'
import routes from '@utils/constants/routes'

const TIMEOUT = 60000

// if you want another config, create one!!
const DEFAULTCONFIG: AxiosRequestConfig = {
    baseURL: awsConfig.BASEURL,
    timeout: TIMEOUT
}

const NO_NEED_AUTH_URLS = ['auth/login']
const NO_NEED_EVENT_URLS = ['/companies/stats/onboarding-status/']

const request = Axios.create(DEFAULTCONFIG)

request.interceptors.request.use(config => {
    const token = getCookie(COOKIE_KEYS.ALA_TOKEN)
    const userRole = getCookie(COOKIE_KEYS.ALA_USER_TYPE)
    const event = getCookie(COOKIE_KEYS.ALA_EVENT)
    if (!NO_NEED_AUTH_URLS.includes(config.url) && !isEmpty(token) && !config.url.includes('/by-token')) {
        config.headers['Authorization'] = `Bearer ${token}`
        if (NO_NEED_EVENT_URLS.every(item => !config.url.includes(item))) {
            if (userRole && event) config.headers['event-id'] = event
        }
    }
    return config
})

let errorCount = 0
request.interceptors.response.use(
    function (response) {
        return response.data
    },
    function (error: AxiosError) {
        // This array includes some endpoints that must not logout the user when call them
        // const whiteListURLs = ['/companies/stats/onboarding-status/']
        // const isAllowedURL = whiteListURLs.reduce((prev, item) => prev || error.config.url.includes(item), false)
        const whiteListErrorMsgEndpoints = [
            '/users/chat-details/login',
            '/notifications/campaigns/run-campaign',
            '/reporting/agendas/send',
            '/companies/imports/excel/finalize',
            '/reporting/agendas/event-report',
            '/users/chat-details/contact/',
            '/companies/contacts',
            '/meeting/user-availabilities/calendar/validate/link',
            '/meeting/direct-meeting',
            '/notifications/verification/validate'
        ]
        const forceShowMessageEndpoints = ['/meeting/sync/calendar/sync/by-token']
        const statusCodeListToShowCustomError = [500, 502, 503]
        const notShowErrorStatuses = [406]
        if (error.response) {
            if (error.response.status === 413) {
                showErrorMessage(
                    'The response size is too large to display. Please apply filters such as date ranges or categories to reduce the data size and enhance performance.'
                )
                return Promise.reject(error)
            }
            const errorMessage = error.response.data.message || error.response.data.error || ''
            if (notShowErrorStatuses.includes(error.response?.status)) {
                return error.response.data
            }
            if (error.response.status === 401 && error.response.data?.statusCode !== 401) {
                errorCount++
                setTimeout(() => {
                    errorCount = 0
                }, 1000)
                removeUserAuthCookie()
                if (errorCount === 1 && error.response.data.message !== 'Unauthorized') {
                    showErrorMessage(error.response.data.message, error.response.data.data)
                }
                return Promise.reject(error)
            }

            if (
                !whiteListErrorMsgEndpoints.some(item => error.response.config.url.includes(item)) &&
                (!statusCodeListToShowCustomError.includes(error.response.status) ||
                    forceShowMessageEndpoints.some(item => error.response.config.url.includes(item))) &&
                errorMessage.length <= 120
            ) {
                showErrorMessage(errorMessage, error.response.data.data)
                return Promise.reject(error)
            }
            if (error.response?.status === 503) {
                return {
                    ...(error.response.data || {}),
                    status: error.response.status
                }
            } else if (statusCodeListToShowCustomError.includes(error.response.status) || errorMessage.length > 120) {
                showErrorMessage('Action not completed. Please refresh or try again in few minutes.')
            } else {
                showErrorMessage(errorMessage, error.response.data.data)
            }
        } else if (error.request) {
            throw new Error(error.request)
        }
        return Promise.reject(error)
    }
)

//TODO some times do better
function showErrorMessage(message, data = []) {
    switch (message) {
        case 'Invalid input':
            notification.warning({
                message:
                    'You are either not registered in this event or using a different email. ' +
                    'Please contact the event organizer.'
            })
            break
        default:
            const config: any = { message }
            if (data?.length) {
                config.description = (
                    <div>
                        {data.map(item => {
                            const link = (
                                <a
                                    href={
                                        item.type === 'Contact'
                                            ? routes.directory.contact_profile.details._.replace(':id', item._id)
                                            : item.type === 'Company'
                                            ? routes.directory.company_profile.profile._.replace(':id', item._id)
                                            : item.type === 'Subscription'
                                            ? routes.settings.subscriptions.subscription._
                                            : '/'
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {item.label}
                                </a>
                            )
                            const messages = item.message.split(item.label)
                            return (
                                <div key={item._id}>
                                    {messages?.[0]} {link} {messages?.[1]}
                                </div>
                            )
                        })}
                    </div>
                )
            }
            notification.warning(config)
            break
    }
}

export const getParams = (payload, isElastic = false) => {
    const query: any = payload.query || undefined
    const pagination = payload.pagination || undefined
    const necessaryProjectionArray = payload.necessaryProjectionArray || undefined
    const needPopulate = payload.needPopulate || undefined
    const search = payload.search || undefined
    const populates = payload.populates || undefined
    const addPresignedURL = payload.addPresignedURL || undefined
    const hasTotal = payload.hasTotal || false
    const skip = payload.skip || undefined
    const sort = payload.sort || undefined
    const limit = payload.limit || undefined
    const searchText = payload.searchText || undefined
    const searchFields = payload.searchFields || []
    const searchRanges = payload.searchRanges || []
    const searchExistenceFields = payload.searchExistenceFields || []
    const searchShouldFields = payload.searchShouldFields || []
    const params = {}

    if (Object.keys(query || {}).length > 0) Object.assign(params, { query })
    if (pagination) Object.assign(params, { ...pagination })
    if (necessaryProjectionArray?.length) Object.assign(params, { necessaryProjectionArray })
    if (addPresignedURL) Object.assign(params, { addPresignedURL })
    if (needPopulate) Object.assign(params, { needPopulate })
    if (search) Object.assign(params, { search })
    if (populates?.length) Object.assign(params, { populates })
    if (hasTotal) Object.assign(params, { hasTotal })
    if (skip) Object.assign(params, { skip })
    if (limit) Object.assign(params, { limit })
    if (searchText) Object.assign(params, { searchText })
    if (searchFields.length) Object.assign(params, { searchFields })
    if (searchRanges.length) Object.assign(params, { searchRanges })
    if (searchExistenceFields.length) Object.assign(params, { searchExistenceFields })
    if (searchShouldFields.length) Object.assign(params, { searchShouldFields })
    if (payload.fields) {
        Object.assign(params, { fields: payload.fields })
    }
    if (!isElastic) {
        if (sort) Object.assign(params, { sort })
    }
    return params
}
export default request
