import { combineReducers } from 'redux'
import listReducer, { listActions } from '@store/slices/perfectMatch/list'
import syncReducer, { syncActions } from '@store/slices/perfectMatch/sync'

export const perfectMatchReducers = combineReducers({
    list: listReducer,
    sync: syncReducer
})

export const perfectMatchActions = {
    list: listActions,
    sync: syncActions
}
