import { Action } from 'redux'
import { IOutlookAuth } from '@app-store/slices/emailCredential/outlookAuthUrl'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'

interface TaskAction extends Action {
    payload: IOutlookAuth
}

const { onRequest, onSuccess, onFailure } = rootActions.emailCredential.outlookAuthUrl

function* outlookAuthURLTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get(`/notifications/email-credentials/outlook-auth-url`)
        if (response.success) {
            yield put(onSuccess(response.result))
            window.open(response.result.url, '', 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100')
            if (payload?.sagaCB?.onSuccess) payload.sagaCB.onSuccess(response.result.url)
        } else {
            throw new Error('')
        }
    } catch (error) {
        if (payload?.sagaCB?.onError) payload.sagaCB.onError(error)
        yield put(onFailure(error))
    }
}

function* outlookAuthURL(): any {
    yield takeLatest<TaskAction>(onRequest, outlookAuthURLTask)
}

export default outlookAuthURL
