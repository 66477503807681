import { combineReducers } from 'redux'
import listReducer, { listActions } from '@app-store/slices/contacts/list'
import advancedListReducer, { advancedListActions } from '@app-store/slices/contacts/advancedList'
import assignedListReducer, { assignedListActions } from '@app-store/slices/contacts/assignedList'
import oneContactReducer, { oneContactActions } from '@app-store/slices/contacts/oneContact'
import editReducer, { editActions } from '@app-store/slices/contacts/edit'
import fileUploadReducer, { fileUploadActions } from '@app-store/slices/contacts/fileUpload'
import getPreSignedUploadURLReducer, {
    getPreSignedUploadURLActions
} from '@app-store/slices/contacts/getPreSignedUploadURL'
import contactViewReducer, { contactViewActions } from '@app-store/slices/contacts/view.slice'
import deleteContactReducer, { deleteContactActions } from '@app-store/slices/contacts/delete'
import moveToCompanyReducer, { moveToCompanyActions } from '@app-store/slices/contacts/moveToCompany'
import checkInOutReducer, { checkInOutActions } from '@app-store/slices/contacts/checkInOut'
import contactByTokenReducer, { contactByTokenActions } from '@app-store/slices/contacts/contactByToken'
import contactsStatusStatisticsReducer, { contactsStatusStatisticsActions } from './contactsStatusStatistics'
import searchReducer, { searchActions } from './search'
import contactVerifyReducer, { contactVerifyActions } from '@app-store/slices/contacts/contactVerify'
import contactListVerifyReducer, { contactListVerifyActions } from '@app-store/slices/contacts/contactListVerify'
import revealReducer, { revealActions } from '@app-store/slices/contacts/reveal'

export const contactsReducer = combineReducers({
    list: listReducer,
    advancedList: advancedListReducer,
    edit: editReducer,
    assignedList: assignedListReducer,
    oneContact: oneContactReducer,
    fileUpload: fileUploadReducer,
    getPreSignedUploadURL: getPreSignedUploadURLReducer,
    view: contactViewReducer,
    delete: deleteContactReducer,
    moveToCompany: moveToCompanyReducer,
    checkInOut: checkInOutReducer,
    contactByToken: contactByTokenReducer,
    contactsStatusStatistics: contactsStatusStatisticsReducer,
    search: searchReducer,
    contactVerify: contactVerifyReducer,
    contactListVerify: contactListVerifyReducer,
    reveal: revealReducer
})

export const contactsActions = {
    list: listActions,
    advancedList: advancedListActions,
    edit: editActions,
    assignedList: assignedListActions,
    oneContact: oneContactActions,
    fileUpload: fileUploadActions,
    getPreSignedUploadURL: getPreSignedUploadURLActions,
    view: contactViewActions,
    delete: deleteContactActions,
    moveToCompany: moveToCompanyActions,
    checkInOut: checkInOutActions,
    contactByToken: contactByTokenActions,
    contactsStatusStatistics: contactsStatusStatisticsActions,
    search: searchActions,
    contactVerify: contactVerifyActions,
    contactListVerify: contactListVerifyActions,
    reveal: revealActions
}
