import { put, takeLatest, select } from 'redux-saga/effects'
import { Action } from 'redux'
import { RootState } from '@app-store/store'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IAddCreditsPayload } from '@app-store/slices/companySubscriptions/addCredits'
import notification from 'antd/lib/notification'

interface TaskAction extends Action {
    payload: IAddCreditsPayload
}

const { onSuccess, onRequest, onFailure } = rootActions.companySubscriptions.addCredits

function* addCreditsSagaTask({ payload }) {
    try {
        const response = yield axios.patch(
            `/subscriptions/company-subscriptions/${payload.subscriptionId}/add-credits`,
            payload.data
        )
        if (response.success) {
            yield put(onSuccess(response.result))
            const { data: i18n } = yield select((store: RootState) => store.localization.locals)
            notification.success({
                message: i18n?.TradeHub?.feature_edited_successfully || 'Feature edited successfully.'
            })
            if (payload.sagaCB) {
                yield payload.sagaCB?.onSuccess(response.result)
            }
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* addCreditsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, addCreditsSagaTask)
}

export default addCreditsSaga
