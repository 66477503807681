import { LOCALSTORAGE_KEYS } from '@features/General'
import cloneDeep from 'lodash/cloneDeep'
import dayjs from 'dayjs'
import store from '@app-store/store'
import { rootActions } from '@app-store/slices'

export const selectRoom = (
    id,
    title,
    avatar,
    position,
    userId,
    currentUserId,
    ws,
    maximumOpenChats,
    onSuccess = () => undefined,
    contactId = ''
) => {
    store.dispatch(
        rootActions.ui.common.addOpenSiderChat({
            id,
            maximumOpenChats,
            data: {
                id,
                title,
                avatar,
                position,
                userId,
                currentUserId,
                contactId,
                messages: [],
                isFetchingMessages: true
            }
        })
    )
    store.dispatch(
        rootActions.chat.getMessages.onRequest({
            username: id,
            noSaveInStore: true,
            sagaCB: {
                onSuccess: result => {
                    readMessages(ws, id)
                    store.dispatch(rootActions.ui.chat.setRead({ roomId: id }))
                    store.dispatch(rootActions.ui.common.setActiveChat(id))
                    store.dispatch(
                        rootActions.ui.common.editOpenSiderChat({
                            id,
                            data: {
                                messages: groupMessagesByDate(result),
                                isFetchingMessages: false
                            }
                        })
                    )
                    onSuccess()
                }
            }
        })
    )
}
export const getAuthToken = () => {
    const chatInfo = localStorage.getItem(LOCALSTORAGE_KEYS.ROCKET_CHAT_INFO)
    if (chatInfo) {
        const { authToken, userId } = JSON.parse(chatInfo)
        return {
            token: authToken,
            userId: userId
        }
    } else {
        return {
            token: null,
            userId: null
        }
    }
}

const uniqueIds = {
    login: '0',
    fetchHistory: '1',
    sendMessage: '5',
    subscribeRoom: '3',
    subscribeStatus: '6',
    setOnlineStatus: '7',
    typingStatus: '8',
    archiveRoom: '97',
    changeMessage: '42'
}

export const connectMessage = ws => {
    const openingMessage = {
        msg: 'connect',
        version: '1',
        support: ['1', 'pre2', 'pre1']
    }
    ws.current.send(JSON.stringify(openingMessage))
}

export const pongMessage = ws => {
    ws.current.send(JSON.stringify({ msg: 'pong' }))
}

export const login = ws => {
    const { token } = getAuthToken()
    if (token) {
        const msg = {
            msg: 'method',
            method: 'login',
            id: '42',
            params: [{ resume: token }]
        }
        ws.current.send(JSON.stringify(msg))
    }
}

export const sendMessage = (ws, msg, rid) => {
    const msgConfig = {
        msg: 'method',
        method: 'sendMessage',
        id: uniqueIds.sendMessage,
        params: [
            {
                rid,
                msg
            }
        ]
    }
    ws.current.send(JSON.stringify(msgConfig))
}

export const fetchHistory = (ws, groupId) => {
    const msg = {
        msg: 'method',
        method: 'loadHistory',
        id: uniqueIds.fetchHistory,
        params: [groupId, null, 50, null]
    }
    ws.current.send(JSON.stringify(msg))
}

export const subscribeToRoom = (ws, groupId) => {
    const msg = {
        msg: 'sub',
        id: uniqueIds.subscribeRoom,
        name: 'stream-room-messages',
        params: [groupId, false]
    }
    ws.current.send(JSON.stringify(msg))
}

export const unsubscribeToRoom = ws => {
    const msg = {
        msg: 'unsub',
        id: uniqueIds.subscribeRoom
    }
    ws.current.send(JSON.stringify(msg))
}

export const subscribeUserStatus = ws => {
    const msg = {
        msg: 'sub',
        id: uniqueIds.subscribeStatus,
        name: 'stream-notify-logged',
        params: ['user-status', false]
    }

    ws.current.send(JSON.stringify(msg))
}

export const isUserStatus = ({ msg, collection, id }) => {
    return msg === 'changed' && collection === 'stream-notify-logged' && id === uniqueIds.subscribeStatus
}

export const setOnlineStatus = ws => {
    const msg = {
        msg: 'method',
        method: 'UserPresence:setDefaultStatus',
        id: uniqueIds.setOnlineStatus,
        params: ['online']
    }
    ws.current.send(JSON.stringify(msg))
}

export const subscriptionUser = (ws, event) => {
    const { userId } = getAuthToken()
    const config = {
        msg: 'sub',
        id: '42',
        name: 'stream-notify-user',
        params: [`${userId}/${event}`, false]
    }
    ws.current.send(JSON.stringify(config))
}

export const typingIndicatorSub = (ws, groupId, username, typing) => {
    const msg = {
        msg: 'method',
        id: '42',
        method: 'stream-notify-room',
        params: [`${groupId}/typing`, username, typing]
    }
    ws.current.send(JSON.stringify(msg))
}

export const getSubscription = ws => {
    const getSubscriptionConfig = {
        msg: 'method',
        method: 'subscriptions/get',
        id: '42',
        params: [{ $date: 1480377601 }]
    }
    ws.current.send(JSON.stringify(getSubscriptionConfig))
}

export const typingIndicator = (ws, groupId, username, typing) => {
    const msg = {
        msg: 'method',
        method: 'stream-notify-room',
        id: '8',
        params: [`${groupId}/typing`, username, typing]
    }
    ws.current.send(JSON.stringify(msg))
}

export const scrollToLast = messageContainerId => {
    if (messageContainerId && document.getElementById(messageContainerId)) {
        document.getElementById(messageContainerId).scrollTo({
            top: document.getElementById(messageContainerId).scrollHeight,
            behavior: 'smooth'
        })
    }
}

export const scrollToMessage = (messageContainerId, anchorMessage, removeAnchorMessage) => {
    // messageContainer.scrollTop = height;
    const messageDom = document.getElementById(anchorMessage)
    messageContainerId &&
        document.getElementById(messageContainerId).scrollTo({
            top: messageDom.offsetTop - 80,
            behavior: 'smooth'
        })
    removeAnchorMessage()
}

export const createDirectMessage = (ws, userId) => {
    const createMessageConfig = {
        msg: 'method',
        method: 'createDirectMessage',
        id: uniqueIds.changeMessage,
        params: [userId]
    }
    ws.current.send(JSON.stringify(createMessageConfig))
}

export const handleCreateDirectMessage = (
    username,
    userId,
    directMessageList,
    isSiderChat = false,
    ws = undefined,
    maximumOpenChats = 1,
    onSuccess = () => undefined,
    contactId = ''
) => {
    const existUser = directMessageList.find(
        item => item._id === userId || item.userId === userId || (item?.uids || []).includes(userId)
    )
    const { userId: loggedInUserId } = getAuthToken()
    if (existUser) {
        const userNameIndex = existUser.uids.findIndex(item => item !== loggedInUserId)
        const currentUserNameIndex = existUser.uids.findIndex(item => item === loggedInUserId)
        const userTitle = existUser.name || existUser.username || existUser.usernames[0] || existUser.email
        const userId = existUser?.uids?.length ? existUser.uids[userNameIndex] : ''
        const currentUserId = existUser?.uids?.length ? existUser.uids[currentUserNameIndex] : ''

        if (isSiderChat) {
            selectRoom(
                existUser._id,
                userTitle,
                existUser?.avatar,
                existUser?.position,
                userId,
                currentUserId,
                ws,
                maximumOpenChats,
                onSuccess,
                contactId
            )
        } else {
            const payload: any = {
                id: existUser._id,
                title: userTitle,
                avatar: existUser?.avatar,
                position: existUser?.position,
                userId,
                currentUserId
            }
            store.dispatch(rootActions.ui.chat.setSelectedRoom(payload))
        }
    } else {
        store.dispatch(
            rootActions.chat.createDirectMessage.onRequest({
                data: { username },
                sagaCB: {
                    onSuccess: data => {
                        store.dispatch(
                            rootActions.chat.listDirectMessage.onRequest({
                                sagaCB: {
                                    onSuccess: () => {
                                        const userNameIndex = data?.uids?.findIndex(item => item !== loggedInUserId)
                                        const currentUserNameIndex = data?.uids?.findIndex(
                                            item => item === loggedInUserId
                                        )
                                        const userTitle = data.name || data.username || data.usernames[0] || data.email
                                        const userId = data?.uids?.length ? data.uids[userNameIndex] : ''
                                        const currentUserId = data?.uids?.length ? data.uids[currentUserNameIndex] : ''
                                        if (isSiderChat) {
                                            selectRoom(
                                                data._id,
                                                userTitle,
                                                data?.avatar,
                                                data?.position,
                                                userId,
                                                currentUserId,
                                                ws,
                                                maximumOpenChats,
                                                onSuccess,
                                                contactId
                                            )
                                        } else {
                                            const payload = {
                                                id: data._id,
                                                title: userTitle,
                                                avatar: data?.avatar,
                                                position: data?.position,
                                                userId,
                                                currentUserId
                                            }
                                            store.dispatch(rootActions.ui.chat.setSelectedRoom(payload))
                                        }
                                    }
                                }
                            })
                        )
                    }
                }
            })
        )
    }
}

export const deleteDirectMessage = (ws, message_id) => {
    const deleteMessageConfig = {
        msg: 'method',
        method: 'deleteMessage',
        id: uniqueIds.changeMessage,
        params: [{ _id: message_id }]
    }
    ws.current.send(JSON.stringify(deleteMessageConfig))
}

export const readMessages = (ws, roomId) => {
    const readMessagesConfig = {
        msg: 'method',
        method: 'readMessages',
        id: '42',
        params: [roomId]
    }
    ws.current.send(JSON.stringify(readMessagesConfig))
}

export const getPublicSettings = ws => {
    const settingConfig = {
        msg: 'method',
        method: 'public-settings/get',
        id: '42'
    }
    ws.current.send(JSON.stringify(settingConfig))
}

const getUnix = msg => msg.ts.$date || dayjs(msg.ts, 'YYYY-MM-DD hh:mm AZ').unix() * 1000
export const groupMessagesByDate = messages => {
    const groups = cloneDeep(messages)
        .sort((a, b) => getUnix(a) - getUnix(b))
        .reduce((groups, message) => {
            const { msgDate, msgResult } = getMsgInfo(message)
            if (!groups[msgDate]) {
                groups[msgDate] = []
            }
            groups[msgDate].push(msgResult)
            return groups
        }, {})

    const groupArrays = Object.keys(groups).map(date => {
        const today = dayjs()
        const yesterday = dayjs().add(-1, 'days')
        const mDate = dayjs(date, 'YYYY-MM-DD hh:mm AZ')
        return {
            date,
            dateTitle: mDate.isSame(today, 'day')
                ? 'Today'
                : mDate.isSame(yesterday, 'day')
                ? 'Yesterday'
                : mDate.format('DD MMMM YYYY'),
            messages: groups[date]
        }
    })
    return groupArrays.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())
}

export const getMsgInfo = msg => {
    const msgDateTime = dayjs.unix(getUnix(msg) / 1000)
    const msgDate = msgDateTime.format().toString().substring(0, 10)

    return {
        msgDate,
        msgResult: { ...msg, time: msgDateTime.format('hh:mm a') }
    }
}

export const addMessage = (messages, newMessage) => {
    const newMessages = [...messages]
    const { msgDate, msgResult } = getMsgInfo(newMessage)

    if (newMessages.length && newMessages[newMessages.length - 1].date === msgDate) {
        const existMsg = newMessages[newMessages.length - 1].messages.find(item => item._id === msgResult._id)
        if (!existMsg) {
            newMessages[newMessages.length - 1].messages.push(msgResult)
        }
    } else {
        newMessages.push({
            date: msgDate,
            dateTitle: 'Today',
            messages: [msgResult]
        })
    }
    return newMessages
}

export const messageIsMine = message => {
    const authToken = getAuthToken()
    return authToken.userId === message.u._id
}
