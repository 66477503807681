import oneSaga from './oneSaga'
import listSaga from './listSaga'
import addUserSaga from './addUserSaga'
import editUserSaga from './editUserSaga'
import deleteUserSaga from './deleteUserSaga'
import addFeatureSaga from './addFeatureSaga'
import editFeatureSaga from './editFeatureSaga'
import deleteFeatureSaga from './deleteFeatureSaga'
import getActiveSaga from './getActive'
import getSummarySaga from './getSummary'
import addCreditsSaga from './addCredits'

const companySubscriptionsSagas = [
    oneSaga(),
    listSaga(),
    addUserSaga(),
    editUserSaga(),
    deleteUserSaga(),
    addFeatureSaga(),
    editFeatureSaga(),
    deleteFeatureSaga(),
    getActiveSaga(),
    getSummarySaga(),
    addCreditsSaga()
]

export default companySubscriptionsSagas
