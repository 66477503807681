import { createSlice } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import { addMessage } from '@utils/helpers/chatHelpers'

export interface ICommonState {
    isSiderOpen: boolean
    isChatSiderOpen: boolean
    openSiderChats: any
    activeChat: string
    maximumOpenChats: number
    mode: 'light' | 'dark'
    supportModalVisible: boolean
    userMenuVisible: boolean
}

const initialState: ICommonState = {
    isSiderOpen: false,
    isChatSiderOpen: false,
    openSiderChats: [],
    activeChat: '',
    maximumOpenChats: 1,
    mode: 'light',
    supportModalVisible: false,
    userMenuVisible: false
}

const commonSlice = createSlice({
    name: 'ui/common',
    initialState,
    reducers: {
        toggleSider(state) {
            state.isSiderOpen = !state.isSiderOpen
        },
        setMaximumOpenChats(state, action) {
            state.maximumOpenChats = action.payload
        },
        onChangeItems(state, action) {
            Object.keys(action.payload).forEach(key => {
                state[key] = action.payload[key]
            })
        },
        toggleUserMenuVisible(state) {
            state.userMenuVisible = !state.userMenuVisible
        },
        toggleChatVisible(state) {
            state.isChatSiderOpen = !state.isChatSiderOpen
        },
        setActiveChat(state, action) {
            state.activeChat = action.payload
        },
        addOpenSiderChat(state, action) {
            const newState = cloneDeep(state.openSiderChats)
            const item = newState.find(item => item.id === action.payload.id)
            if (!item) {
                if (state.openSiderChats.length === action.payload.maximumOpenChats) {
                    newState.splice(0, 1)
                }
                newState.push(action.payload.data)
            } else if (item?.contactId && action.payload.data.contactId) {
                item.contactId = action.payload.data.contactId
            }
            state.openSiderChats = newState
        },
        addChatMessage(state, { payload }) {
            const newState = cloneDeep(state.openSiderChats)
            const item = newState.find(item => item.id === (payload.id || payload.rid))
            if (item) {
                item.messages = addMessage(item.messages, payload)
            }
            state.openSiderChats = newState
        },
        editOpenSiderChat(state, action) {
            const newState = cloneDeep(state.openSiderChats)
            const item = newState.find(item => item.id === action.payload.id)
            if (item) {
                for (const key in action.payload.data) {
                    item[key] = action.payload.data[key]
                }
            }
            state.openSiderChats = newState
        },
        removeOpenSiderChat(state, action) {
            const newState = cloneDeep(state.openSiderChats.filter(item => item.id !== action.payload.roomId))
            state.openSiderChats = newState
        },
        minimizeOpenSiderChat(state, action) {
            const newState = cloneDeep(state.openSiderChats)
            const item: any = newState.find(item => item.id === action.payload.roomId)
            if (item) {
                item.minimized = !item.minimized
            }
            state.openSiderChats = newState
        },
        onToggleMode(state) {
            state.mode = state.mode === 'light' ? 'dark' : 'light'
        }
    }
})

export const commonActions = commonSlice.actions
export default commonSlice.reducer
