import React from 'react'
import { ModalFuncProps } from 'antd/lib/modal'

export const imageFileSize = 6
export const imgExtensions = [
    // 'xbm',
    // 'tif',
    // 'pjp',
    // 'apng',
    // 'svgz',
    // 'ico',
    // 'tiff',
    // 'svg',
    // 'jfif',
    // 'webp',
    'bmp',
    // 'pjpeg',
    // 'avif',
    'png',
    'jpg',
    'jpeg',
    'gif'
]
export const videoExtensions = ['ogm', 'wmv', 'mpg', 'webm', 'ogv', 'mov', 'asx', 'mpeg', 'mp4', 'm4v', 'avi']
export const documentExtensions = ['dot', 'doc', 'xls', 'ppt', 'text', 'txt', 'pdf']
export const userGuideMapping = {
    '/matching/match_engine': 84899,
    '/matching/match-list': 84899,
    '/organizer': 84787,
    '/directory/published/companies': 85062,
    '/directory': 85062,
    '/responses': 85054,
    '/matching/match-list/details/': 85054,
    '/settings/event/info': 85066,
    '/analytics': 85063,
    '/trade-hub/pipeline': 85059,
    '/meetings/incoming': 85058,
    '/matching/matches/meeting-wish-list': 85055,
    '/view': 85052
}

/*
    Request statuses
 */
export const STATUS = {
    IDLE: 'idle',
    RUNNING: 'running',
    READY: 'ready',
    ERROR: 'error'
}

export const BREAKPOINTS = {
    XS: 480,
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1200,
    XXL: 1600
}

export const modalConfig = params => {
    const {
        title,
        contentText,
        onOk,
        icon = null,
        okText = '',
        cancelText = '',
        onCancel,
        wrapClassName = '',
        width
    } = params
    return {
        wrapClassName,
        title,
        icon,
        okButtonProps: {
            className: 'primary-solid'
        },
        okText: okText || title,
        onOk,
        cancelButtonProps: {
            className: 'secondary-ghost'
        },
        cancelText,
        content: <p>{contentText}</p>,
        onCancel,
        width
    } as ModalFuncProps
}

export enum COOKIE_KEYS {
    LANG = 'ALAEDDIN_LOCALE',
    ALA_TOKEN = 'ALA_TOKEN',
    ALA_DIRECTION = 'ALA_DIRECTION',
    ALA_REFRESH = 'ALA_REFRESH_TOKEN',
    ALA_USER_ROLE = 'ALA_USER_ROLE',
    ALA_USER_TYPE = 'ALA_USER_TYPE',
    ALA_EVENT = 'ALA_EVENT',
    ALA_COLOR = 'ALA_COLOR'
}

export enum LOCALSTORAGE_KEYS {
    USERINFO = 'userInfo',
    EVENT_LIST = 'EVENT_LIST',
    CONTACT_INFO = 'contactInfo',
    NAV_OPEN_KEYS = 'navOpenKeys',
    SIDE_BAR_THEME = 'sideBarTheme',
    SIDE_BAR_COLLAPSED = 'sideBarCollapsed',
    NOT_SUPPORT_POLLING = 'notSupportPolling',
    NAVIGATION_POSTION = 'navigationPosition',
    GMAIL_AUTH_CODE = 'gmailAuthCode',
    ONBOARDING_ORGANIZER = 'onboarding/organizer',
    ROCKET_CHAT_INFO = 'chatInfo',
    MENU_VISIBILITY = 'menuVisibility',
    ACTIVE_FEATURES = 'activeFeatures'
}

export const DayOfWeek = [
    { label: 'Every day', value: 'Every_Day' },
    { label: 'Mon - Fri', value: 'Mon_Fri' },
    { label: 'Sat - Sun', value: 'Sat_Sun' },
    { label: 'Sunday', value: 'Sunday' },
    { label: 'Monday', value: 'Monday' },
    { label: 'Tuesday', value: 'Tuesday' },
    { label: 'Wednesday', value: 'Wednesday' },
    { label: 'Thursday', value: 'Thursday' },
    { label: 'Friday', value: 'Friday' },
    { label: 'Saturday', value: 'Saturday' }
]
export const Every_Day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const Mon_Fri = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
export const Sat_Sun = ['Sunday', 'Saturday']
export const Every_Day_Number = [0, 1, 2, 3, 4, 5, 6]
export const Mon_Fri_Day_Number = [1, 2, 3, 4, 5]
export const Sat_Sun_Day_Number = [0, 6]

export const csvDownloadOptions = (filename, excelTitle = '') => ({
    filename,
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: !!excelTitle,
    title: excelTitle || '',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true
})

export enum containerType {
    iTPStatus = 'iTPStatus',
    registrationStatus = 'registrationStatus',
    profileStatus = 'profileStatus',
    filterStatus = 'filterStatus'
}
export enum fieldsName {
    companySize = 'companySize',
    companyType = 'companyType',
    fundingStage = 'fundingStage',
    numberOfEmployees = 'numberOfEmployees',
    yearFounded = 'yearFounded',
    hQAddress = 'HQAddress',
    numberOfFounders = 'numberOfFounders',
    investmentTicket = 'investmentTicket',
    industries = 'industries',
    industry = 'industry',
    fortune = 'fortune',
    announceDate = 'announceDate',
    technologyUsed = 'technologyUsed',
    estimatedAnnualRevenue = 'estimatedAnnualRevenue'
}

export const websiteRegex =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/
export const phoneRegex =
    /^(?:00|\+)?(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/

// export const whatsappNumberSupport = '971558855530'
export const whatsappNumberSupport = '971528273220'
