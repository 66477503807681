import { createSlice } from '@reduxjs/toolkit'
import { listUIInitialState } from '@utils/constants/requestConstants'
import { listUIReducers } from '../reducers'
const orderPaymentHistorySlice = createSlice({
    name: 'ui/order-payment-history',
    initialState: {
        ...listUIInitialState,
        visibleChartModal: false,
        populates: []
    },
    reducers: listUIReducers
})

export const orderPaymentHistoryActions = orderPaymentHistorySlice.actions
export default orderPaymentHistorySlice.reducer
