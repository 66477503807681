import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IGenerateLinkPayload } from '@app-store/slices/survey/generateLink'

interface TaskAction extends Action {
    payload: IGenerateLinkPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.survey.generateLink

function* generateLinkSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.get('/meeting/survey/generate-survey-link', {
            params: payload.data
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload?.sagaCB) payload.sagaCB?.onSuccess(response.result)
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* generateLinkSaga(): any {
    yield takeLatest<TaskAction>(onRequest, generateLinkSagaTask)
}

export default generateLinkSaga
