import { Action } from 'redux'
import { put, takeLatest } from 'redux-saga/effects'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { IDeleteUnavailabilityByTokenPayload } from '@app-store/slices/meetings/deleteUnavailabilityByToken'

interface TaskAction extends Action {
    payload: IDeleteUnavailabilityByTokenPayload
}

const { onRequest, onSuccess, onFailure } = rootActions.meetings.deleteUnavailabilityByToken

function* deleteUnavailabilityByTokenSagaTask({ payload }: TaskAction) {
    try {
        const response = yield axios.post(`/meeting/unavailable/remove/by-token?token=${payload.token}`, {
            ids: payload.ids
        })
        if (response.success) {
            yield put(onSuccess(response.result))
            if (payload.sagaCB) {
                yield payload.sagaCB.onSuccess(response.result)
            }
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* deleteUnavailabilityByTokenSaga(): any {
    yield takeLatest<TaskAction>(onRequest, deleteUnavailabilityByTokenSagaTask)
}

export default deleteUnavailabilityByTokenSaga
