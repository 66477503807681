import listSaga from './listSaga'
import createSaga from './createSaga'
import editSaga from './editSaga'
import getGoogleAuthUrlSaga from './getGoogleAuthUrlSaga'
import oneEmailCredentialSaga from './oneEmailCredentialSaga'
import deleteEmailCredentialSaga from './deleteSaga'
import CheckSesAccountStatusSaga from './checkSesAccountStatusSaga'
import outlookAuthURL from './outlookAuthUrlSaga'

const emailCredentialSagas = [
    listSaga(),
    createSaga(),
    editSaga(),
    getGoogleAuthUrlSaga(),
    oneEmailCredentialSaga(),
    deleteEmailCredentialSaga(),
    CheckSesAccountStatusSaga(),
    outlookAuthURL()
]

export default emailCredentialSagas
