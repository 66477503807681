import { put, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { rootActions } from '@app-store/slices'
import axios from '@utils/request'
import { ILocalsPayload } from '@app-store/slices/localization/locals'

interface TaskAction extends Action {
    payload: ILocalsPayload
}
const { onRequest, onSuccess, onFailure } = rootActions.localization.locals

function* localsSagaTask({ payload }) {
    const { bundle, extraKeys, language } = payload

    const params = {}
    if (language) Object.assign(params, { language })
    if (bundle) Object.assign(params, { bundle })
    if (extraKeys) Object.assign(params, { extraKeys })
    try {
        const response = yield axios.get(
            language ? '/integrations/localization/by-language' : '/integrations/localization',
            {
                params
            }
        )
        if (response.success) {
            yield put(onSuccess(response.result))
        } else {
            throw new Error('')
        }
    } catch (error) {
        yield put(onFailure(error))
    }
}

function* localsSaga(): any {
    yield takeLatest<TaskAction>(onRequest, localsSagaTask)
}

export default localsSaga
