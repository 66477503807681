import { combineReducers } from 'redux'
import eventDetailsViewReducer, { eventDetailsViewActions } from './view.slice'

export const eventDetailsReducer = combineReducers({
    view: eventDetailsViewReducer
})

export const eventDetailsActions = {
    view: eventDetailsViewActions
}
